/* eslint-disable import/no-duplicates */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RouterPath } from '../../common';

// API
import { apiLoginKakaoCallback } from '../../api/RestAPI';

// Util
import Utils from '../../utils/Utils';
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 로그인 > Kakao 콜백 URL ]
// ***********************************************************
const LoginCallbackKakao = () => {
  const navigate = useNavigate();
  const modal = useSelector(state => state.modal);
  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get('code');
  if (!code) navigate(-1);

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 로그인 성공 > 접근 페이지 이동 ( Default = Home )
  const redirectPage = () => {
    navigate(modal.data?.pathname || RouterPath.Home);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // Kakao 로그인 Callback
  const requestKakaoToken = async () => {
    try {
      const { data } = await apiLoginKakaoCallback(code);

      if (data) {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            accessToken: data.data.accessToken,
            refreshToken: data.data.refreshToken,
          }),
        );

        Utils.closeModal({
          closeEvent: redirectPage,
        });
      }
    } catch (e) {
      handleError(e, () => navigate(-1));
      // navigate(-1);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    requestKakaoToken();
  }, []);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return <div> </div>;
};

export default LoginCallbackKakao;
