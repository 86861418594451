/* eslint-disable react/jsx-no-undef */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
// import { Container, Overlay } from 'react-bootstrap';
import LoginModal from './LoginModal';
import BasicModal from './BasicModal';
import ConfirmModal from './ConfirmModal';
import PopupModal from './PopupModal';

// Redux > Util
import Utils from '../../utils/Utils';

const MODAL_TYPES = {
  LoginModal: 'LoginModal',
  BasicModal: 'BasicModal',
  ConfirmModal: 'ConfirmModal',
  PopupModal: 'PopupModal',
};

const MODAL_COMPONENTS = [
  {
    type: MODAL_TYPES.LoginModal,
    component: <LoginModal />,
  },
  {
    type: MODAL_TYPES.BasicModal,
    component: <BasicModal />,
  },
  {
    type: MODAL_TYPES.ConfirmModal,
    component: <ConfirmModal />,
  },
  {
    type: MODAL_TYPES.PopupModal,
    component: <PopupModal />,
  },
];

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 10;
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

// ***********************************************************
// [ 글로벌 모달 ]
// ***********************************************************
function GlobalModal() {
  const { modalType, isOpen, closeEvent } = useSelector(state => state.modal);

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 모달 타입 추출
  const findModal = MODAL_COMPONENTS.find(modal => {
    return modal.type === modalType;
  });

  // 모달 컴포넌트 반환
  const renderModal = () => {
    return findModal?.component;
  };
  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // 클린업 함수는 필요 없어짐
  }, [isOpen]); //

  if (!isOpen) return;
  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <Container>
      <Overlay onClick={() => Utils.closeModal({ closeEvent })} />
      {renderModal()}
    </Container>
  );
}

export { MODAL_TYPES };
export default GlobalModal;
