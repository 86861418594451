/* eslint-disable import/no-duplicates */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RouterPath } from '../../common';

// API
import { apiLoginGoogleCallback } from '../../api/RestAPI';

// Util
import Utils from '../../utils/Utils';
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 로그인 > Google 콜백 URL ]
// ***********************************************************
const LoginCallbackGoogle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modal = useSelector(state => state.modal);

  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 로그인 성공 > 접근 페이지 이동 ( Default = Home )
  const redirectPage = () => {
    navigate(modal.data?.pathname || RouterPath.Home);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // Google 로그인 Callback
  const requestGoogleCallback = async () => {
    try {
      const { data } = await apiLoginGoogleCallback(code);

      if (data) {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            accessToken: data.data.accessToken,
            refreshToken: data.data.refreshToken,
          }),
        );

        Utils.closeModal({
          closeEvent: redirectPage,
        });
      }
    } catch (e) {
      handleError(e, () => navigate(-1));
      // navigate(-1);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    if (code) {
      requestGoogleCallback();
    } else {
      navigate(-1);
    }
  }, [code]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************
  return <div> </div>;
};

export default LoginCallbackGoogle;
