/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Constants
import { RouterPath } from '@common';

// Components
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiPostReservationSend } from '../../api/RestAPI';

// Image
import { images } from '../../assets';
import { Utils } from '../../common';

// ***********************************************************
// [ 게시판 > 상담예약 ]
// ***********************************************************
export default React.memo(function ReservationsClear(props) {
  const navigate = useNavigate();

  // 이전 페이지 동의 유무
  const location = useLocation();
  const isConfirmed = location.state?.isConfirmed;

  // 잘못된 접근 > 모달 & 뒤로가기
  if (!isConfirmed)
    Utils.openModal({
      body: '잘못된 접근입니다.',
      closeEvent: () => navigate(-1),
    });

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="reservations-clear">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.reservationsImg} alt="메인 이미지" />
        </div>
      </section>
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-list">
            <div className="contents-img">
              <img src={images.icClear} alt="접수 완료 아이콘" />
            </div>
            {/* 안내 */}
            <div className="contents-title">
              <h2>상담 예약 접수 완료</h2>
              <p>
                상담 신청이 접수되었습니다.
                <span> 빠른 시간 내에 연락 드리겠습니다.</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
