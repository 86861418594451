import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { images } from '../../assets';
import { apiGetPopupList } from '../../api/RestAPI';
import POPUP_TYPE from '../../common/constants/PopupType';
import { handleError } from '../../utils/HandleError';
import { Utils } from '../../common';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';

export default React.memo(function Focus(props) {
  const navigate = useNavigate();

  const POPUP_DISABLED = 'popup_disable';
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 팝업
  const [activePopup, setActivePopup] = useState(0);
  const [popupList, setPopupList] = useState([]); // 팝업 리스트

  // ***********************************************************
  // [ API ]
  // ***********************************************************
  const getPopupList = async () => {
    try {
      const { data } = await apiGetPopupList(POPUP_TYPE.CLINIC_FOCUS.code);

      if (data) {
        const popups = data.data;

        // Local Storage
        let disabledList = localStorage.getItem(POPUP_DISABLED);
        disabledList = disabledList ? JSON.parse(disabledList) : [];

        // Local Storage 정보 O
        if (disabledList.length > 0) {
          // 유효하지 않은 Storage 데이터 제거 & 재설정
          disabledList = disabledList.filter(item => today === `${item.date}`);

          localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

          // 필터
          setPopupList(
            popups.filter(item => {
              return !disabledList.find(el => el.idx === item.popupIdx);
            }),
          );
        }
        // Local Storage 정보 X
        else {
          setPopupList(popups);
        }
      }
    } catch (e) {
      handleError(e);
    }
  };
  // ***********************************************************
  // [ Util ]
  // ***********************************************************
  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  const handleReviewClick = () => {
    navigate('/review');
  };
  // 팝업 출력
  const openPopup = activeIdx => {
    if (activeIdx < popupList.length) {
      const popup = popupList[activeIdx];

      Utils.openModal({
        type: MODAL_TYPES.PopupModal,
        data: {
          actionLink: popup.actionLink,
          imageName: popup.imageName,
          imagePath: popup.imagePath,
          mHeight: popup.mHeight,
          mWidth: popup.mWidth,
          orderNo: popup.orderNo,
          pcHeight: popup.pcHeight,
          pcWidth: popup.pcWidth,
          popupIdx: popup.popupIdx,
          hasNext: activeIdx < popupList.length - 1,
        },
        closeEvent: () => closePopup(activeIdx), // 닫기
        confirmEvent: () => disablePopup(activeIdx), // 하루동안 미출력
      });
    }
  };

  // 팝업 > 하루동안 미출력
  const disablePopup = activeIdx => {
    // Local Storage
    let disabledList = localStorage.getItem(POPUP_DISABLED);
    disabledList = disabledList ? JSON.parse(disabledList) : [];

    // 선택 팝업 미출력 처리
    disabledList.push({ idx: popupList[activeIdx].popupIdx, date: today });
    localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

    // 다음 팝업
    setActivePopup(activeIdx + 1);
  };

  // 팝업 > 닫기
  const closePopup = activeIdx => {
    setActivePopup(activeIdx + 1);
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  // 팝업 리스트 조회
  useEffect(() => {
    getPopupList();
  }, []);

  // 팝업 순차 출력
  useEffect(() => {
    if (popupList.length > 0) {
      openPopup(activePopup);
    }
    return () => {};
  }, [popupList, activePopup]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************
  return (
    <main id="Focus">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.focusImg1} alt="메인 이미지" />
          <div className="main-text">
            <h2>골든스터디</h2>
            <p className="text">집중력 연구센터</p>
          </div>
        </div>
        <div className="main-contents-text inner">
          <div className="sub-title">
            <h2>
              내 아이 SKY 프로젝트,
              <br />
              집중력이 핵심입니다
            </h2>
          </div>
          <div className="sub-text">
            <p>
              학년이 올라갈수록 어려워지는 공부.
              <br /> 점점 높아지는 학습 난이도와 경쟁에도 뒤쳐지지 않는 든든한
              발판을 만들어 주고 싶으시죠.
            </p>
            <p className="m-0">
              내 아이에게 꼭 필요한 성공 경험,
              <br /> 직면하는 어려움과 문제를 끈기 있게 맞서 싸우고 이겨내는
              힘을 가진 아이로 성장하는 여정을{' '}
              <span>골든스터디 집중력 연구센터</span>가 함께하겠습니다.
            </p>
          </div>
        </div>
      </section>
      <section className="contents-section1">
        <div className="contents-container inner">
          <div className="contents-img">
            <img src={images.focusImg2} alt="이미지 1" />
          </div>
          <div className="contents-text">
            <div className="sub-title">
              <h2>
                내 아이의 집중력,
                <br />
                어떻게 키울 수 있을까?
              </h2>
            </div>
            <div className="sub-text">
              <p className="text">
                내 아이의 생활 속에서 집중력 방해 요소를 꼼꼼히 살펴보세요.
                <br /> 집중력 저하의 원인은 신체 불균형, 스트레스, 체력 저하
                등으로 인한 몸의 회복력 저하와 그에 따른 혈행장애입니다.
                혈행장애가 생긴 부위는 자가회복을 위해 ’염증 - 손상 - 회복’의
                연쇄 면역반응을 일으키며, 이 과정이 만성화될수록 지속적인 집중력
                저하의 원인이 됩니다.
              </p>
              <div className="text-box">
                <p className="m-0">
                  집중력도 성장합니다.
                  <br /> 골든힐은 집중력 회복과 성장의 근본인 3요소를 체계적으로
                  다룹니다.
                </p>
                <ul className="index-text">
                  <li>1. 체력 증진</li>
                  <li>2. 스트레스 해소</li>
                  <li>3. 혈행 개선 및 염증 완화</li>
                </ul>
              </div>
              <p className="m-0">
                아이의 몸과 마음 상태, 일상 속 작은 생활습관까지 세심하게
                검토하는 정확한 진단이 집중력 회복과 성장의 첫 단추입니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section2">
        <div className="contents-container inner">
          <div className="contents-img">
            <img src={images.focusImg3} alt="" />
          </div>
          <div className="contents-text">
            <div className="sub-title">
              <h2>
                100년 전통 한의학 비방과
                <br /> 현대 의학 지식의 조화
              </h2>
            </div>
            <div className="sub-text">
              <p className="m-0">
                100년 한의 명가만의 한방 지식을 과학적으로 연구하여 현대
                의학에서 치료 효과가 검증된 처방이 담긴 성장 프로그램으로
                발전시키는 노력을 끊임없이 이어가고 있습니다. 체계화된 100년
                전통 골든힐의 비방으로 아이의 집중력 성장을 돕습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section3">
        <div className="contents-container inner">
          <div className="contents-box">
            <h2>
              내 아이에게 딱 맞는
              <br />
              골든스터디 집중력 연구센터
            </h2>
            <ul className="contents-list">
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg4} alt="" />
                </div>
                <p className="title">
                  <span>1</span>체질 및 건강 상태 검사
                </p>
                <p className="info">
                  아이의 몸 상태, 질환, 개인 식습관, 생활습관 등, 라이프스타일
                  전반 요소를 면밀히 검토해 집중력 저하 원인을 분석합니다.
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg5} alt="" />
                </div>
                <p className="title">
                  <span>2</span>1:1 맞춤 상담 진료
                </p>
                <p className="info">
                  개별 진료 상담으로 아이의 상태와 생활 패턴에 적합한 집중력
                  성장 계획을 설계합니다.
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg6} alt="" />
                </div>
                <p className="title">
                  <span>3</span>집중력 한약 처방
                </p>
                <p className="info">
                  내 아이의 집중력 성장에 꼭 필요한 요소를 고려해 100년 전통
                  한의학 비방을 담아 현대의학의 치료 효과가 검증된 영양 한약을
                  처방합니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contents-section4">
        <div className="contents-container inner">
          <div className="contents-text">
            <div className="contents-img">
              <img src={images.logoSymbolImg} alt="" />
            </div>
            <h2>
              골든스터디 <br className="br-500" />
              집중력 연구센터는 <br className="br-500" />
              <span>
                시험기간 단기 집중력 개선과 장기적인 집중력 성장,
              </span>{' '}
              <br className="br-500" />
              신체 균형 최적화를 <br className="br-500" />
              목표로 합니다.
            </h2>
            <p className="text">
              꾸준한 집중력 성장에 자양분이 되는 안정성과
              <br /> 효과가 입증된 약재만을 엄선해 최적의 처방 프로그램을
              설계합니다.
            </p>
          </div>
          <div className="contents-box">
            <h2>집중력 성장 골든스터디 맞춤 한약</h2>
            <ul className="contents-list">
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg7} alt="" />
                  <div className="gmp-img">
                    <img src={images.gmpImg} alt="" />
                  </div>
                </div>
                <p className="title">
                  <span>1</span>안정성
                </p>
                <p className="info">
                  FDA 인증, 대한한의사협회 인증, GMP 시설 인증을 받은 재료부터
                  완성 과정까지 안전한 한약을 믿고 복용할 수 있습니다.
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg8} alt="" />
                </div>
                <p className="title">
                  <span>2</span>개인 최적화
                </p>
                <p className="info">
                  아이의 현재 몸 상태, 질환, 개인 식습관, 생활패턴을 고려해
                  맞춤형 처방을 받을 수 있습니다
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.focusImg9} alt="" />
                </div>
                <p className="title">
                  <span>3</span>입증된 효과
                </p>
                <p className="info">
                  공진단, 경옥고, 홍삼진 등 효과가 입증된 다양한 한약을 아이의
                  상태에 맞게 활용해 효과적인 집중력 향상에 도움을 줄 수
                  있습니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contents-section5">
        <div className="contents-container inner">
          <div className="contents-box">
            <div className="contents-title">
              <div className="contents-icon">
                <img src={images.icMemo} alt="memo" />
              </div>
              <h2>
                골든스터디 <br className="br-500" />
                집중력 연구센터 프로그램 후기
              </h2>
            </div>
            <Button
              className="btn-outline btn-outline-primary"
              onClick={handleReviewClick}
            >
              집중력 치료 후기 보기
            </Button>
          </div>
        </div>
      </section>
      <section className="contents-section6">
        <div className="contents-container">
          <div className="contents-box inner">
            <h2>
              내 아이에게 꼭 맞는 <br />
              <span>집중력 성장 기회</span>를 <br className="br-500" />
              놓치지 마세요
            </h2>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton className="top-btn" />

      <Footer />
    </main>
  );
});
