import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// Component
import { Button } from 'react-bootstrap';
import { images } from '../../assets';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import KakaoMap from '../../components/Kakao/KakaoMap';

// Constant
import { RouterPath } from '../../common';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';

// API
import { apiGetBannerList, apiGetPopupList } from '../../api/RestAPI';

// Util
import Utils from '../../utils/Utils';
import { handleError } from '../../utils/HandleError';
import POPUP_TYPE from '../../common/constants/PopupType';

const POPUP_DISABLED = 'popup_disable';
const now = new Date();
const today = format(now, 'yyyy-MM-dd');

// ***********************************************************
// [ 홈 > 메인 페이지 ]
// ***********************************************************
export default React.memo(function Home(props) {
  const navigate = useNavigate();

  // 상담 예약 페이지 이동
  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  // Redux Store 조회
  const info = useSelector(state => {
    return state.info;
  });

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 팝업
  const [activePopup, setActivePopup] = useState(0);
  const [popupList, setPopupList] = useState([]); // 팝업 리스트

  // 배너
  const [bannerList, setBannerList] = useState([]); // 배너 리스트
  const [currentBanner, setCurrentBanner] = useState(0); // 현재 배너 인덱스
  const [isPlaying, setIsPlaying] = useState(true); // 슬라이드 재생 상태
  const [dragStart, setDragStart] = useState(null); // 드래그 상태

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 한의원 소개 페이지 이동
  const handleIntroductionClick = () => {
    navigate(RouterPath.Introduction); // 'introduction'은 라우트 경로에 맞게 조정하세요.
  };

  // 슬라이더 재생/정지 토글 함수
  const toggleSlider = () => {
    setIsPlaying(!isPlaying);
  };

  // 마우스/터치 드래그 시작 처리
  const handleDragStart = e => {
    const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
    setDragStart(clientX);
  };

  // 마우스/터치 드래그 끝 처리
  const handleDragEnd = e => {
    if (dragStart !== null) {
      const clientX = e.type.includes('mouse')
        ? e.clientX
        : e.changedTouches[0].clientX;
      const dragEnd = clientX;
      const dragDistance = dragStart - dragEnd;

      if (dragDistance > 100) {
        // 오른쪽으로 드래그
        setCurrentBanner(prevIndex => (prevIndex + 1) % bannerList.length);
      } else if (dragDistance < -100) {
        // 왼쪽으로 드래그
        setCurrentBanner(
          prevIndex => (prevIndex - 1 + bannerList.length) % bannerList.length,
        );
      }
    }
    setDragStart(null);
  };

  // 팝업 출력
  const openPopup = activeIdx => {
    if (activeIdx < popupList.length) {
      const popup = popupList[activeIdx];

      Utils.openModal({
        type: MODAL_TYPES.PopupModal,
        data: {
          actionLink: popup.actionLink,
          imageName: popup.imageName,
          imagePath: popup.imagePath,
          mHeight: popup.mHeight,
          mWidth: popup.mWidth,
          orderNo: popup.orderNo,
          pcHeight: popup.pcHeight,
          pcWidth: popup.pcWidth,
          popupIdx: popup.popupIdx,
          hasNext: activeIdx < popupList.length - 1,
        },
        closeEvent: () => closePopup(activeIdx), // 닫기
        confirmEvent: () => disablePopup(activeIdx), // 하루동안 미출력
      });
    }
  };

  // 팝업 > 하루동안 미출력
  const disablePopup = activeIdx => {
    // Local Storage
    let disabledList = localStorage.getItem(POPUP_DISABLED);
    disabledList = disabledList ? JSON.parse(disabledList) : [];

    // 선택 팝업 미출력 처리
    disabledList.push({ idx: popupList[activeIdx].popupIdx, date: today });
    localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

    // 다음 팝업
    setActivePopup(activeIdx + 1);
  };

  // 팝업 > 닫기
  const closePopup = activeIdx => {
    setActivePopup(activeIdx + 1);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 배너 리스트
  const getBannerList = async () => {
    try {
      const { data } = await apiGetBannerList();

      if (data) {
        setBannerList(data.data);
      }
    } catch (e) {
      handleError(e);
    }
  };

  // 팝업 리스트
  const getPopupList = async () => {
    try {
      const { data } = await apiGetPopupList(POPUP_TYPE.MAIN.code);

      if (data) {
        const popups = data.data;

        // Local Storage
        let disabledList = localStorage.getItem(POPUP_DISABLED);
        disabledList = disabledList ? JSON.parse(disabledList) : [];

        // Local Storage 정보 O
        if (disabledList.length > 0) {
          // 유효하지 않은 Storage 데이터 제거 & 재설정
          disabledList = disabledList.filter(item => today === `${item.date}`);
          localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

          // 필터
          setPopupList(
            popups.filter(item => {
              return !disabledList.find(el => el.idx === item.popupIdx);
            }),
          );
        }
        // Local Storage 정보 X
        else {
          setPopupList(popups);
        }
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  // 배너, 팝업 리스트 조회
  useEffect(() => {
    getBannerList();
    getPopupList();
  }, []);

  // 배너 인덱스 자동 업데이트
  useEffect(() => {
    let timer;
    if (isPlaying && bannerList.length > 1) {
      timer = setInterval(() => {
        setCurrentBanner(prevIndex => (prevIndex + 1) % bannerList.length);
      }, 10000); // 10초마다 인덱스 업데이트
    }
    return () => clearInterval(timer);
  }, [isPlaying, bannerList.length]);

  // 팝업 순차 출력
  useEffect(() => {
    if (popupList.length > 0) {
      openPopup(activePopup);
    }
    return () => {};
  }, [popupList, activePopup]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************
  return (
    <main id="home">
      <Header type="basic" />
      <article className="home-container">
        {/* TODO ::: 배너 > 슬라이드로 대체 */}
        <section
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onTouchCancel={handleDragEnd} // 터치가 갑자기 중단되었을 때
          className="main-section"
          // style={{ cursor: dragStart !== null ? 'grabbing' : 'grab' }}
        >
          {bannerList.length > 0 ? (
            <div key={`banner-img-${currentBanner}`} className="main-container">
              <div className="main-img">
                <img
                  src={`${process.env.REACT_APP_STORAGE_URL}/${bannerList[currentBanner].bannerImagePath}`}
                  alt={`banner-${currentBanner}`}
                />
              </div>
              <div className="main-contents inner">
                <div className="main-top">
                  <div className="main-box">
                    <h2 className="title" style={{ whiteSpace: 'pre-line' }}>
                      {bannerList[currentBanner].bannerMainText}
                    </h2>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {bannerList[currentBanner].bannerSubText}
                    </p>
                  </div>
                </div>
                <div className="main-bottom">
                  <div className="main-btn">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleReservationsClick}
                    >
                      상담예약
                    </Button>
                    <div className="btn-box" onClick={toggleSlider}>
                      <div className="text">
                        {currentBanner + 1} / {bannerList.length}
                      </div>
                      <button type="submit">
                        {isPlaying ? (
                          <img src={images.icStop} alt="정지" />
                        ) : (
                          <img src={images.icPlay} alt="재생" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="banner-gauge">
                    {bannerList.map((_, index) => (
                      <span
                        key={`gauge-${index}`}
                        className={index === currentBanner ? 'active' : ''}
                        onClick={() => setCurrentBanner(index)} // 클릭 시 해당 인덱스로 이동
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div key="banner-img-default" className="main-container">
              <div className="main-img" style={{ opacity: 0.3 }} />
            </div>
          )}
        </section>

        {/* TODO ::: 팝업 > 모달로 대체 */}
        {/* <section>
          {popupList.length > 0 &&
            popupList.map((popup, index) => {
              return (
                <div key={`popup-img-${index}`}>
                  <img
                    src={`${process.env.REACT_APP_STORAGE_URL}/${popup.imagePath}`}
                    alt={`popup-${index}`}
                  />
                </div>
              );
            })}
        </section> */}

        {/* 진료분야 */}
        <section className="contents-section1 inner">
          <div className="contents-container">
            <h2>원하시는 진료과목을 선택해주세요</h2>
            <div className="contents-list">
              <div
                className="contents-item"
                onClick={() => navigate(RouterPath.Growth)}
              >
                <div className="contents-img">
                  <img src={images.homeImg1} alt="" />
                  <div className="contents-text">
                    <p className="contents-name">키성장</p>
                    <p className="contents-info">
                      골든타임
                      <br />
                      키성장
                      <br />
                      빅데이터
                      <br />
                      연구센터
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="contents-item"
                onClick={() => navigate(RouterPath.Prostate)}
              >
                <div className="contents-img">
                  <img src={images.homeImg2} alt="" />
                  <div className="contents-text">
                    <p className="contents-name">전립선</p>
                    <p className="contents-info">
                      골든에이지
                      <br />
                      전립선
                      <br />
                      솔루션
                      <br />
                      연구센터
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="contents-item"
                onClick={() => navigate(RouterPath.Focus)}
              >
                <div className="contents-img">
                  <img src={images.homeImg3} alt="" />
                  <div className="contents-text">
                    <p className="contents-name">집중력</p>
                    <p className="contents-info">
                      골든스터디
                      <br />
                      집중력
                      <br />
                      연구센터
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="contents-item"
                onClick={() => navigate(RouterPath.Diet)}
              >
                <div className="contents-img">
                  <img src={images.homeImg4} alt="" />
                  <div className="contents-text">
                    <p className="contents-name">다이어트</p>
                    <p className="contents-info">
                      골든밸런스
                      <br />
                      다이어트 센터
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contents-section2">
          <div className="contents-container">
            <div className="contents-box inner">
              <h2>溫故而知新</h2>
              <h2 className="title">온고이지신</h2>
              <hr />
              <p>
                전통의 가치에 현대 의학의 기술과 지식을 더해 환자분에게 맞는 더
                정확한 진단과 치료를 제공하고 있습니다.
              </p>
              <Button
                className="btn-introduction btn-outline-primary"
                onClick={handleIntroductionClick}
              >
                한의원 소개
              </Button>
            </div>
          </div>
        </section>
        <section className="contents-section3 inner">
          <div className="contents-container">
            <div className="title">
              <h2>진료 시간</h2>
            </div>
            <div className="contents-box">
              <div className="contents-list">
                <div className="contents-item">
                  <p className="text">월요일~금요일</p>
                  <p>
                    10:00-19:00
                    <ul>
                      <li className="text-list">휴게시간 13:00-14:00</li>
                      <li className="text-list">접수마감 18:00</li>
                    </ul>
                  </p>
                </div>
                {/* <div className="responsive-span">{}</div> */}
                <div className="contents-item">
                  <p className="text">토요일</p>
                  <p>
                    10:00-14:00 <p>(휴게시간 없이 진료합니다)</p>
                  </p>
                </div>
                {/* <div className="responsive-span">{}</div> */}
                <div className="contents-item">
                  <p className="text">일요일, 공휴일</p>
                  <p>휴진</p>
                </div>
              </div>
              <div className="contents-info">
                <div className="info">
                  <p>*</p>
                  <p>
                    골든힐 한의원의 모든 진료는 100% 예약제로 진행됩니다. 방문
                    전 상담 예약을 부탁드립니다.
                  </p>
                </div>
                <div className="info">
                  <p>*</p>
                  <p>일요일, 공휴일은 휴진입니다.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contents-section4 inner">
          <div className="contents-container">
            <div className="title">
              <h2>오시는 길</h2>
            </div>
            <div className="contents-box">
              <div className="map">
                {/* 지도 */}
                <KakaoMap />
              </div>
              {/* 도로명 주소 */}
              <div className="contents-list">
                <div className="contents-item">
                  <p className="text">도로명 주소</p>
                  <p>
                    {info?.addressNew ||
                      '서울특별시 강남구 도산대로 409 (청담동 87-4) S&S타워 2층'}
                  </p>
                </div>
                {/* 지번 주소 */}
                <div className="contents-item">
                  <p className="text">지번 주소</p>
                  <p>
                    {info?.addressOld ||
                      '서울특별시 강남구 도산대로 409 (청담동 87-4) S&S타워 2층'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      {/* 최상단 이동 */}
      <TopButton className="top-btn" />

      <Footer />
    </main>
  );
});
