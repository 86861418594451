import React from 'react';
import { Map, CustomOverlayMap } from 'react-kakao-maps-sdk';

export default React.memo(function KakaoMap(props) {
  return (
    <Map
      center={{ lat: 37.523692, lng: 127.040482 }}
      style={{ width: '100%', height: '610px' }}
      level={3}
    >
      <CustomOverlayMap
        position={{ lat: 37.523692, lng: 127.040482 }}
        yAnchor={1}
        xAnchor={0.5}
      >
        <div>
          <div
            className="overlay"
            style={{
              position: 'relative',
              background: 'rgb(6, 120, 240)',
              borderRadius: '1.5rem',
              fontSize: '1rem',
              fontWeight: '600',
              padding: '0.75rem',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              color: '#FFF',
            }}
          >
            골든힐 한의원
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '-6px', // 위치 조정
              left: '50%',
              marginLeft: '-5px',
              borderWidth: '6px 6px 0 6px', // 변경: 상, 우, 하, 좌
              borderStyle: 'solid',
              borderColor:
                'rgb(6, 120, 240) transparent transparent transparent', // 변경: 상 색상만 지정
            }}
          />
        </div>
      </CustomOverlayMap>
    </Map>
  );
});
