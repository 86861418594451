import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Button } from 'react-bootstrap';

// Router
import { RouterPath } from '@common';

// Redux
import { useSelector } from 'react-redux';

// Utils
import Utils from '../../utils/Utils';

// Image
import { images } from '../../assets';

const now = new Date().getFullYear();

// ***********************************************************
// [ 푸터 ]
// ***********************************************************
export default React.memo(function Footer(props) {
  const navigate = useNavigate();

  // Redux Store 조회
  const info = useSelector(state => {
    return state.info;
  });

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 새 브라우저 탭 열기
  const openNewTab = url => {
    if (!url) Utils.openModal({ text: '준비중입니다.' });
    else window.open(url);
  };

  // 개인정보 처리방침 이동
  const moveToTermPrivate = () => {
    navigate(RouterPath.TermPrivate);
  };

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <footer id="footer">
      <div className="footer-container inner">
        <div className="footer-top">
          <div className="footer-title">
            <div className="logo">
              <img src={images.footerLogo} alt="logo" />
            </div>
            <div className="info-box">
              <ul className="info">
                <li>
                  대표: {info?.ceoName || '한혁규'}
                  <span>|</span>상호명: {info?.businessName || '골든힐 한의원'}
                </li>
                <li className="responsive-span">|</li>
                <li>
                  사업자등록번호: {info?.businessNumber || '342-04-02268'}
                </li>
                <li className="responsive-span">|</li>
                <li>
                  {info?.addressNew ||
                    '서울특별시 강남구 도산대로 409, 2층 (청담동, S&S타워)'}
                </li>
              </ul>
              <div className="responsive-box">
                <button
                  type="submit"
                  className="btn-outline-primary"
                  onClick={moveToTermPrivate}
                >
                  개인정보처리방침
                </button>
                {/* SNS 링크 */}
                <ul className="icon-box">
                  {/* Kakao */}
                  <li>
                    <div onClick={() => openNewTab(info?.kakaoUrl)}>
                      <img src={images.footerIcon1} alt="kakao" />
                    </div>
                  </li>
                  {/* Naver */}
                  <li>
                    <div onClick={() => openNewTab(info?.naverUrl)}>
                      <img src={images.footerIcon2} alt="blog" />
                    </div>
                  </li>
                  {/* Youtube */}
                  <li>
                    <div onClick={() => openNewTab(info?.youtubeUrl)}>
                      <img src={images.footerIcon3} alt="youtube" />
                    </div>
                  </li>
                  {/* Instagram */}
                  <li>
                    <div onClick={() => openNewTab(info?.instagramUrl)}>
                      <img src={images.footerIcon4} alt="instar" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© {now} GOLDENHILL1925. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
});
