import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

// Components
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiGetTermDetail } from '../../api/RestAPI';

// Util
import { handleError } from '../../utils/HandleError';

// Constants
import TERMS_TYPE from '../../common/constants/TermsType';

// Image
// import { images } from '../../assets';

// ***********************************************************
// [ 약관 > 개인정보 처리방침 ]
// ***********************************************************
export default React.memo(function TermPrivate(props) {
  // ***********************************************************
  // [ State ]
  // ***********************************************************

  const iframeRef = useRef(null);

  // 약관 상세
  const [termDetail, setTermDetail] = useState({
    termsNo: '',
    termsLabel: '',
    termsCategory: '',
    termsFileName: '',
    termsFilePath: '',
  });

  // 메시지 수신 이벤트 핸들러
  const receiveMessage = event => {
    // TODO: 여기서는 모든 메시지를 받지만, 실제로는 출처를 확인하고, 메시지의 유효성을 검사해야 합니다.
    if (event.data.frameHeight && iframeRef.current) {
      iframeRef.current.style.height = `${event.data.frameHeight}px`;
    }
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 약관 상세
  const getTermDetail = async () => {
    try {
      const { data } = await apiGetTermDetail(TERMS_TYPE.TERMS_PRIVATE.code);

      if (data) {
        const info = data.data;

        setTermDetail({
          termsNo: info?.termsNo,
          termsLabel: info?.termsLabel,
          termsCategory: info?.termsCategory,
          termsFileName: info?.termsFileName,
          termsFilePath: info?.termsFilePath,
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getTermDetail();
  }, []);

  useEffect(() => {
    getTermDetail();

    // 메시지 리스너를 추가합니다.
    window.addEventListener('message', receiveMessage, false);

    // 컴포넌트가 언마운트 될 때 메시지 리스너를 정리합니다.
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="private">
      {/* 헤더 */}
      <Header type="basic" />

      {/* 약관 상세 */}
      <section className="main-section inner">
        {/* 타이틀 */}
        {/* 본문 */}
        <div className="main-contents">
          <h2>{termDetail.termsLabel}</h2>
          <iframe
            ref={iframeRef}
            id="myIframe"
            className="w-100"
            // style={{ minHeight: '4000px', overflow: 'hidden' }}
            title={termDetail.termsCategory}
            src={`${process.env.REACT_APP_STORAGE_URL}/${termDetail.termsFilePath}`}
          />
        </div>
      </section>

      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
