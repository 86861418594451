/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';

// Utils
import { images } from '../../../assets';
// ***********************************************************
// [ 모달 > 로그인 > 카카오 버튼 ]
// ***********************************************************
const LoginButtonKakao = () => {
  const CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID; // REST_API Key
  const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
  const AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URI;

  // URL
  const KAKAO_AUTH_URL = `${AUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <>
      <Link to={KAKAO_AUTH_URL} className="login">
        <button className="login-btn">
          <div className="login-icon">
            <img src={images.icModalKakao} alt="kakao" />
          </div>
          카카오 로그인
        </button>
      </Link>
    </>
  );
};

export default LoginButtonKakao;
