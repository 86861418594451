import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import { images } from '../../assets';
import CallButton from '../../components/CallButton/CallButton';
import { Utils } from '../../common';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';
import { handleError } from '../../utils/HandleError';
import { apiGetPopupList } from '../../api/RestAPI';
import POPUP_TYPE from '../../common/constants/PopupType';

export default React.memo(function Prostate(props) {
  const navigate = useNavigate();
  const POPUP_DISABLED = 'popup_disable';
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');
  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 팝업
  const [activePopup, setActivePopup] = useState(0);
  const [popupList, setPopupList] = useState([]); // 팝업 리스트

  // ***********************************************************
  // [ API ]
  // ***********************************************************
  const getPopupList = async () => {
    try {
      const { data } = await apiGetPopupList(POPUP_TYPE.CLINIC_PROSTATE.code);

      if (data) {
        const popups = data.data;

        // Local Storage
        let disabledList = localStorage.getItem(POPUP_DISABLED);
        disabledList = disabledList ? JSON.parse(disabledList) : [];

        // Local Storage 정보 O
        if (disabledList.length > 0) {
          // 유효하지 않은 Storage 데이터 제거 & 재설정
          disabledList = disabledList.filter(item => today === `${item.date}`);
          localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

          // 필터
          setPopupList(
            popups.filter(item => {
              return !disabledList.find(el => el.idx === item.popupIdx);
            }),
          );
        }
        // Local Storage 정보 X
        else {
          setPopupList(popups);
        }
      }
    } catch (e) {
      handleError(e);
    }
  };
  // ***********************************************************
  // [ Util ]
  // ***********************************************************
  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  const handleReviewClick = () => {
    navigate('/review');
  };
  // 팝업 출력
  const openPopup = activeIdx => {
    if (activeIdx < popupList.length) {
      const popup = popupList[activeIdx];

      Utils.openModal({
        type: MODAL_TYPES.PopupModal,
        data: {
          actionLink: popup.actionLink,
          imageName: popup.imageName,
          imagePath: popup.imagePath,
          mHeight: popup.mHeight,
          mWidth: popup.mWidth,
          orderNo: popup.orderNo,
          pcHeight: popup.pcHeight,
          pcWidth: popup.pcWidth,
          popupIdx: popup.popupIdx,
          hasNext: activeIdx < popupList.length - 1,
        },
        closeEvent: () => closePopup(activeIdx), // 닫기
        confirmEvent: () => disablePopup(activeIdx), // 하루동안 미출력
      });
    }
  };

  // 팝업 > 하루동안 미출력
  const disablePopup = activeIdx => {
    // Local Storage
    let disabledList = localStorage.getItem(POPUP_DISABLED);
    disabledList = disabledList ? JSON.parse(disabledList) : [];

    // 선택 팝업 미출력 처리
    disabledList.push({ idx: popupList[activeIdx].popupIdx, date: today });
    localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

    // 다음 팝업
    setActivePopup(activeIdx + 1);
  };

  // 팝업 > 닫기
  const closePopup = activeIdx => {
    setActivePopup(activeIdx + 1);
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  // 팝업 리스트 조회
  useEffect(() => {
    getPopupList();
  }, []);

  // 팝업 순차 출력
  useEffect(() => {
    if (popupList.length > 0) {
      openPopup(activePopup);
    }
    return () => {};
  }, [popupList, activePopup]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************

  return (
    <main id="prostate">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.prostateImg1} alt="메인 이미지" />
          <div className="main-text">
            <h2>골든에이지</h2>
            <p className="text">전립선 솔루션 연구센터</p>
          </div>
        </div>
      </section>
      <section className="contents-section1 contents-section-top">
        <div className="contents-container inner">
          <div className="contents-group">
            <div className="contents-text-box">
              <div className="contents-text-top">
                <div className="sub-title">
                  <h2>
                    4대를 이어온
                    <br /> 전립선 비방과 연구
                  </h2>
                </div>

                <div className="sub-text">
                  <p className="text">
                    선대의 경험과 현대 한의학을 접목하고 연구하여
                    <br />
                    더 효과적인 비방으로
                    <br />
                    찾아오시는 모든 분의 전립선 건강을 <br className="br-500" />
                    책임지겠습니다.
                  </p>
                </div>
              </div>

              <div className="m-contents-img">
                <img src={images.prostateImg8} alt="대표 이미지" />
              </div>

              <div className="contents-text-bottom">
                <div className="sub-title">
                  <h2>한혁규 박사 (4대 원장)</h2>
                  <p>한류 스타, 스포츠 스타, 유명 기업인 주치의</p>
                </div>

                <div className="sub-text">
                  <div className="sub-title-text">
                    <h2>한승섭 박사 (3대 원장)</h2>
                    <p className="text">정치인, 기업 총수 주치의</p>
                  </div>
                  <div className="sub-title-text">
                    <h2>한정식 원장 (2대 원장)</h2>
                    <p className="text">박정희 대통령 주치의</p>
                  </div>
                  <div className="sub-title-text">
                    <h2>이귀례 원장 (1대 원장)</h2>
                    <p className="text">이승만 대통령 주치의</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pc-contents-img">
              <img src={images.prostateImg8} alt="대표 이미지" />
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section1">
        <div className="contents-container inner">
          <div className="contents-text">
            <div className="sub-title">
              <h2>
                전립선 질환 고민,
                <br /> 100년 전통 한의 명가
                <br /> 골든힐이 답하다
              </h2>
            </div>
            <div className="sub-text text-box">
              <p className="text">
                업무 중에도, 여행 중에도, 일상 생활에서 시도때도 없이 찾아오는
                빈뇨.
                <br />
                화장실을 다녀와도 남아 있는 잔뇨.
                <br />
                밤부터 새벽까지 수시로 찾아와 숙면을 방해하는 야간뇨.
              </p>
              <p className="text">
                이제는 떨쳐버리고 자신감을 되찾고 싶으시죠.
              </p>
              <p className="text m-0">
                남자의 자신감을 되찾아 줄 전립선 회복의 시작.
                <br />
                활력 넘치는 인생을 위해 골든힐 한의원
                <br />
                <span>골든에이지 전립선 솔루션 연구센터</span>가 함께
                하겠습니다.
              </p>
            </div>
          </div>
          <div className="contents-text m-0">
            <div className="sub-title">
              <h2>
                100세 시대.
                <br /> 내 전립선은 얼마나
                <br /> 오래 건강할까?
              </h2>
            </div>
            <div className="sub-text">
              <p className="text">
                나이가 들면 찾아오겠거니 했던 전립선 질환은
                <br className="br-500" />더 이상 중장년과
                <br className="br-pc" /> 노년층만의 질환이 아닙니다.
                <br />
                예전과 다르게 50대 보다 젊은 30-40대의 <br className="br-500" />
                연령층에서도 운동 부족,
                <br />
                서구화된 식습관, 불규칙한 생활패턴 등으로 인해
                <br />
                전립선 질환 환자가 늘어나고 있습니다.
              </p>
              <p
                className="link-text pointer"
                onClick={() =>
                  window.open(
                    'https://health.chosun.com/site/data/html_dir/2020/03/31/2020033102864.html',
                  )
                }
              >
                <span className="icon">
                  <img src={images.icLink} alt="아이콘" />
                </span>
                {'<헬스조선> “20~30대 전립선비대증 증가… '}
              </p>
            </div>
          </div>
          <div className="contents-item">
            <div className="contents-img">
              <img src={images.prostateMobileImg} alt="" />
            </div>
            <div className="contents-pc-img">
              <img src={images.prostateImg2} alt="" />
            </div>
            <p>
              100세 시대. 어느 때보다 전립선 건강이 중요합니다. 40대에 전립선
              질환이 시작되는 경우, 50-60년을 더 살아가야 하기 때문에 남성들은
              전립선 질환을 미리 예방하고 관리해야 보다 더 건강하고 행복한
              노후를 누릴 수 있습니다.
            </p>
          </div>
        </div>
      </section>
      <section className="contents-section2">
        <div className="contents-container">
          <div className="contents-text inner">
            <div className="sub-title">
              <h2>
                100년 전통의 비방이 담긴
                <br />
                <span>1:1 개인 맞춤 처방</span>
              </h2>
            </div>
            <div className="sub-text text-box">
              <p className="text">
                1925년 1대 이귀례 원장 시절부터 혈행개선과 관련된 질환을 많이
                치료하였습니다. 수십 년간 고지혈증, 당뇨, 심혈관 질환을 앓는
                환자들에게 한약을 처방, 치료하며 환자들의 혈액이 맑아지고 혈행이
                개선되며 몸에 쌓인 독소와 염증이 소변으로 배출되는 치료 노하우를
                쌓게 되었습니다.
              </p>
              <p className="text m-0">
                오랜 치료 경험을 토대로 많은 남성 환자들의 전립선 통증과 염증
                개선 요인을 터득하였고, 4대를 걸쳐 전해온 수많은 실제 치료
                데이터를 근거로 골든힐 한의원{' '}
                <span>골든에이지 전립선 솔루션 연구센터</span>에서는 체계적이고
                과학적인 전립선 치료 연구를 이어가고 있습니다. 자체 연구개발한
                ‘전립선 환약’과 ‘전립선 탕약’은 남성 전립선 질환 개선에 탁월한
                효능을 보이고 있습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section3">
        <div className="contents-container inner">
          <div className="contents-box">
            <h2>
              문제의 근본을 치료하는
              <br />
              골든에이지 <br className="br-500" />
              전립선 솔루션 연구센터
            </h2>
            <ul className="contents-list">
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.prostateImg5} alt="" />
                </div>
                <p className="title">
                  <span>1</span>환약 처방
                </p>
                <p className="info">
                  신장, 전립선, 요도 등 인체의 하수도가 막힌 것을 뚫어주는
                  역할로 전립선염, 전립선비대의 독소 등 노폐물이 녹아 소변으로
                  배출됩니다.
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.prostateImg6} alt="" />
                </div>
                <p className="title">
                  <span>2</span>탕약 처방
                </p>
                <p className="info">
                  개인의 체질과 증상에 맞는 1:1 맞춤 처방으로 지속적으로
                  재발되는 전립선염 또는 전립선비대로 인한 전립선 기능 저하를
                  개선시키면서 동시에 신장과 방광 기능을 강화시킵니다.
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-img">
                  <img src={images.prostateImg7} alt="" />
                </div>
                <p className="title">
                  <span>3</span>전립선 침/약침
                </p>
                <p className="info">
                  전립선 질환 치료를 위한 혈자리에 침과 약침을 놓아 전립선염 및
                  전립선비대증을 개선하고 동시에 혈액순환을 좋게 하여 전립선의
                  기능을 향상시킵니다.
                </p>
              </li>
            </ul>
            <p className="text">
              골든힐 한의원의 한약은 환자의 증상과 체질에 꼭 맞는 높은 품질의
              한약재만을 엄선해 만듭니다.
              <br /> 항생제 내성에 대한 염려가 없고, 안심하고 복용할 수 있으며
              효능이 탁월합니다.
            </p>
          </div>
        </div>
      </section>
      <section className="contents-section4">
        <div className="contents-container inner">
          <h2>골든힐 전립선 검진 프로그램</h2>
          <ul className="contents-list">
            <li className="contents-item p-0">
              <p className="title">혈액 검사</p>
              <div className="contents-box">
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">PSA 수치</p>
                    <p className="sub-info">
                      전립선 특이 항원(PSA) 수치를 통해 전립선의 건강 상태를
                      평가합니다.
                    </p>
                  </div>
                </div>
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">신장, 간 기능 및 염증 수치</p>
                    <p className="sub-info">
                      전립선 건강과 관련된 신장 및 간 기능과 염증의 정도를
                      파악합니다.
                    </p>
                  </div>
                </div>
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">고지혈 및 중성지방 수치</p>
                    <p className="sub-info">
                      심혈관 건강과 관련된 중요한 지표들을 확인합니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="contents-item">
              <p className="title">수양명 검사</p>
              <div className="contents-box">
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">혈액순환 및 스트레스 검사</p>
                    <p className="sub-info">
                      혈액 순환 상태와 스트레스 수준을 측정합니다.
                    </p>
                  </div>
                </div>
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">신경계 발란스 검사</p>
                    <p className="sub-info">신경계의 균형 상태를 확인합니다.</p>
                  </div>
                </div>
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">면역력 검사</p>
                    <p className="sub-info">
                      전반적인 면역력 상태를 평가합니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="contents-item">
              <p className="title">정밀초음파 검사</p>
              <div className="contents-box">
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">전립선 크기 측정</p>
                    <p className="sub-info">
                      하복부 초음파 장비를 사용하여 전립선의 크기를 정확하게
                      측정합니다. 이 방법은 항문 초음파에 비해 환자분의 불편함과
                      거부감을 크게 줄여줍니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="contents-item">
              <p className="title">소변 검사</p>
              <div className="contents-box">
                <div className="contents-info">
                  <div className="sub-box">
                    <p className="sub-title">
                      2활성산소 및 각종 소변 수치 측정
                    </p>
                    <p className="sub-info">
                      소변을 통해 체내의 활성산소 수준과 다른 중요한 소변
                      지표들을 측정합니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <p className="text">
            * 전립선 진료 시 필요에 따라 당뇨, 혈압, 폐활량 등의 추가 검사가
            진행될 수 있습니다.
          </p>
        </div>
      </section>
      <section className="contents-section5">
        <div className="contents-container inner">
          <div className="contents-box">
            <div className="contents-title">
              <div className="contents-icon">
                <img src={images.icMemo} alt="memo" />
              </div>
              <h2>
                골든에이지 <br className="br-500" />
                전립선 솔루션 연구센터 <br className="br-500" />
                프로그램 후기
              </h2>
            </div>
            <Button
              className="btn-outline btn-outline-primary"
              onClick={handleReviewClick}
            >
              전립선 치료 후기 보기
            </Button>
          </div>
        </div>
      </section>
      <section className="contents-section6">
        <div className="contents-container">
          <div className="contents-box inner">
            <h2>
              건강한 전립선으로 <br className="br-500" />
              힘차고 행복하게
              <br className="br-pc" />내 인생의 <span>골든에이지</span>를 맘껏
              누리세요
            </h2>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* 전화상담 버튼 */}
      <CallButton />

      {/* 최상단 이동 */}
      {/* <TopButton className="top-btn" /> */}

      <Footer />
    </main>
  );
});
