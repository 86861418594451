const AVAILABLE_TIME_TYPE = {
  T001: {
    label: '아무때나',
    value: 'T001',
  },
  T002: {
    label: '오전시간 (09:00~12:00)',
    value: 'T002',
  },
  T003: {
    label: '점심시간 (12:00~14:00)',
    value: 'T003',
  },
  T004: {
    label: '오후시간 (14:00~18:00)',
    value: 'T004',
  },
  T005: {
    label: '18시 이후',
    value: 'T005',
  },
};

export default AVAILABLE_TIME_TYPE;
