/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';

// Components
import LoginButtonGoogle from './Button/LoginButtonGoogle';
import LoginButtonKakao from './Button/LoginButtonKakao';

// Utils
import Utils from '../../utils/Utils';
import { images } from '../../assets';

const LoginModal = ({ closeEvent }) => {
  // ...

  return (
    <Overlay onClick={() => Utils.closeModal({ closeEvent })}>
      <Container className="login-modal">
        <div onClick={Utils.closeModal} className="close-btn">
          <img src={images.icModalClose} alt="close" />
        </div>
        <div className="login-container">
          <div className="icon">
            <img src={images.icModalWarring} alt="warring" />
          </div>
          <p className="text">
            의료법에 의거하여 치료 사례는 회원만 열람이 가능합니다.
          </p>
          <p>
            <span>회원 가입 후 로그인</span>하시면 골든힐과 함께 해주셨던 분들의
            다양한 치료사례들을 보실 수 있습니다.
          </p>
          <div className="login-box">
            {/* 카카오 로그인 */}
            <LoginButtonKakao />

            {/* 구글 로그인 */}
            <LoginButtonGoogle />
          </div>
        </div>
      </Container>
    </Overlay>
  );
};

const Container = styled.section``;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(9px);
`;

export default LoginModal;
