/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';

// Utils
import { images } from '../../../assets';

// ***********************************************************
// [ 모달 > 로그인 > 구글 버튼 ]
// ***********************************************************
const LoginButtonGoogle = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // REST_API Key
  const REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI; // Redirect URL
  const AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URI; // 로그인 URL

  // Google 로그인 화면 URL
  const GOOGLE_AUTH_URL = `${AUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=email profile`;

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <>
      <Link to={GOOGLE_AUTH_URL} className="login">
        <button className="login-btn">
          <div className="login-icon">
            <img src={images.icModalGoogle} alt="google" />
          </div>
          구글 로그인
        </button>
      </Link>
    </>
  );
};

export default LoginButtonGoogle;
