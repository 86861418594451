import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { parse, format } from 'date-fns';

// Constants
import { RouterPath } from '@common';

// Components
import { Button } from 'react-bootstrap';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiGetNoticeDetail } from '../../api/RestAPI';

// Image
import { images } from '../../assets';
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 게시판 > 공지사항 > 상세 ]
// ***********************************************************
export default React.memo(function NoticeDetail(props) {
  const navigate = useNavigate();

  // 조회 게시물 Index
  const params = useParams();
  const noticeIdx = params?.idx;

  // TODO ::: 잘못된 접근 > 모달 & 뒤로가기
  if (!noticeIdx) alert('잘못된 접근입니다.');

  // 이전 페이지 정보
  const location = useLocation();
  const prevPage = location.state?.page || 1;

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 공지 상세
  const [noticeDetail, setNoticeDetail] = useState({
    categoryName: '',
    noticeBody: '',
    noticeFiles: [],
    noticeIdx: '',
    noticeTitle: '',
    regDate: '',
    regTime: '',
    updDate: '',
  });

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 이전 목록으로
  const moveToList = () => {
    navigate(`${RouterPath.Notices}?page=${prevPage}`);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 공지사항 상세
  const getNoticeDetail = async () => {
    try {
      const { data } = await apiGetNoticeDetail(noticeIdx);

      if (data) {
        const info = data.data;

        const regDateTime = parse(
          info.regDate,
          'yyyy-MM-dd HH:mm:ss.S',
          new Date(),
        );

        setNoticeDetail({
          categoryName: info.categoryName,
          noticeBody: info.noticeBody,
          noticeFiles: info.noticeFiles,
          noticeIdx: info.noticeIdx,
          noticeTitle: info.noticeTitle,
          regDate: format(regDateTime, 'yyyy-MM-dd'),
          regTime: format(regDateTime, 'HH:mm'),
          updDate: info.updDate,
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getNoticeDetail();
  }, []);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="noticeDetail">
      {/* 헤더 */}
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.noticesImg} alt="메인 이미지" />
        </div>
      </section>

      {/* TODO :::  공지사항 상세  */}
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-box">
            <h2>공지사항</h2>
            <p className="text">골든힐 한의원의 소식을 알려드립니다.</p>

            {/* 타이틀 */}
            <div className="contents-title">
              <p className="title">{noticeDetail.noticeTitle}</p>
            </div>

            {/* 등록일시 */}
            <div className="contents-day">
              <div className="day">
                <div className="icon">
                  <img src={images.icDay} alt="day-icon" />
                </div>
                <p>{noticeDetail.regDate}</p>
              </div>
              <div className="day">
                <div className="icon">
                  <img src={images.icTime} alt="time-icon" />
                </div>
                <p>{noticeDetail.regTime}</p>
              </div>
            </div>

            {/* 첨부파일 */}
            <div className="contents-img">
              {noticeDetail.noticeFiles.length > 0 &&
                noticeDetail.noticeFiles.map((file, index) => {
                  return (
                    <img
                      key={`notice-img-${index}`}
                      src={`${process.env.REACT_APP_STORAGE_URL}/${file.uploadedFilePath}`}
                      alt={`notice-${index}`}
                    />
                  );
                })}
            </div>

            <p className="contents-text">{noticeDetail.noticeBody}</p>

            {/* 목록 보기 */}
            <Button
              className="btn-outline btn-outline-primary"
              onClick={moveToList}
            >
              목록 보기
            </Button>
          </div>
        </div>
      </section>

      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
