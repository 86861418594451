// icon
import mobileBtn from './icon/ic_mobile_btn.png';
import mobileClose from './icon/ic_mobile_close.png';
import mobileArrow from './icon/ic_arrow_down.png';
import arrowDownBlack from './icon/ic_arrow_down_black.png';
import arrowDownGray from './icon/ic_arrow_down_gary.png';
import circleDown from './icon/ic_circle_down.png';
import arrowRight from './icon/ic_arrow_right.png';
import arrowRightLong from './icon/ic_arrow_right_long.png';
import arrowRightLong1 from './icon/ic_arrow_right_long_1.png';
import footerIcon1 from './icon/ic_footer_1.png';
import footerIcon2 from './icon/ic_footer_2.png';
import footerIcon3 from './icon/ic_footer_3.png';
import footerIcon4 from './icon/ic_footer_4.png';
import uncheck from './icon/ic_uncheck.png';
import check from './icon/ic_check.png';
import landingIcon1 from './icon/ic_landing1.png';
import landingIcon2 from './icon/ic_landing2.png';
import icMemo from './icon/ic_memo.png';
import icDietMemo from './icon/ic_diet_memo.png';
import icDay from './icon/ic_day.png';
import icTime from './icon/ic_time.png';
import icTopButton from './icon/ic_top_button.png';
import icTopButtonWhite from './icon/ic_top_button_white.png';
import icFix from './icon/ic_fix.png';
import icWarning from './icon/ic_ warning.png';
import icPagination1 from './icon/ic_pagination1.png';
import icPagination2 from './icon/ic_pagination2.png';
import icPagination3 from './icon/ic_pagination3.png';
import icPagination4 from './icon/ic_pagination4.png';
import icPlay from './icon/ic_play.png';
import icStop from './icon/ic_stop.png';
import icModalClose from './icon/ic_modal_close.png';
import icModalGoogle from './icon/ic_modal_google.png';
import icModalKakao from './icon/ic_modal_kakao.png';
import icModalWarring from './icon/ic_modal_warring.png';
import icGrowth1 from './icon/ic_growth1.png';
import icGrowth2 from './icon/ic_growth2.png';
import icGrowth3 from './icon/ic_growth3.png';
import icGrowth4 from './icon/ic_growth4.png';
import icGrowth5 from './icon/ic_growth5.png';
import icLink from './icon/ic_link.png';
import icDiet1 from './icon/ic_diet1.png';
import icDiet2 from './icon/ic_diet2.png';
import icDiet3 from './icon/ic_diet3.png';
import icDiet4 from './icon/ic_diet4.png';
import icClear from './icon/ic_clear.png';
import icCallButton from './icon/ic_call_button.png';
import icCountry from './icon/ic_country.png';
import icGoldDiet1 from './icon/ic_gold_diet1.png';
import icGoldDiet2 from './icon/ic_gold_diet2.png';
import icGoldDiet3 from './icon/ic_gold_diet3.png';
import icGoldDiet4 from './icon/ic_gold_diet4.png';
import icGoldDiet5 from './icon/ic_gold_diet5.png';
import icGoldDiet6 from './icon/ic_gold_diet6.png';
import icGoldDiet7 from './icon/ic_gold_diet7.png';
import icGoldDiet8 from './icon/ic_gold_diet8.png';
import icGoldDiet9 from './icon/ic_gold_diet9.png';
import icGoldDiet10 from './icon/ic_gold_diet10.png';
import icGoldDiet11 from './icon/ic_gold_diet11.png';
import icGoldDiet12 from './icon/ic_gold_diet12.png';

// imgae
import logoImg from './img_logo.png';
import logoWhiteImg from './img_logo_white.png';
import logoGreenImg from './img_logo_green.png';
import logoGoldImg from './img_logo_gold.png';
import footerLogo from './img_footer_logo.png';
import landingImg from './img_landing.jpg';
import homeCalendarImg from './img_home_calendar.png';
import homeImg1 from './img_home1.jpg';
import homeImg2 from './img_home2.jpg';
import homeImg3 from './img_home3.jpg';
import homeImg4 from './img_home4.jpg';
import introductionImg1 from './img_introduction1.jpg';
import introductionImg2 from './img_introduction2.png';
import introductionImg3 from './img_introduction3.png';
import mDoctorImg1 from './img_m_doctor1.png';
import doctorImg1 from './img_doctor1.jpg';
import doctorImg2 from './img_doctor2.jpg';
import doctorImg3 from './img_doctor3.png';
import doctorImg4 from './img_doctor4.jpg';
import doctorMbImg4 from './img_mb_doctor4.png';
import doctorImg5 from './img_doctor5.png';
import doctorImg6 from './img_doctor6.png';
import directionsImg from './img_directions.jpg';
import ceoImg1 from './img_ceo1.png';
import ceoImg2 from './img_ceo2.png';
import ceoImg3 from './img_ceo3.png';
import ceoImg4 from './img_ceo4.png';
import growthImg1 from './img_growth1.jpg';
import growthImg2 from './img_growth2.png';
import growthImg3 from './img_growth3.png';
import growthImg4 from './img_growth4.jpg';
import growthImg5 from './img_growth5.jpg';
import growthImg7 from './img_growth7.png';
import growthMobileImg from './img_growth_mobile.png';
import prostateImg1 from './img_prostate1.jpg';
import prostateImg2 from './img_prostate2.jpg';
import prostateImg3 from './img_prostate3.jpg';
import prostateImg5 from './img_prostate5.png';
import prostateImg6 from './img_prostate6.png';
import prostateImg7 from './img_prostate7.png';
import prostateImg8 from './img_prostate8.png';
import prostateMobileImg from './img_prostate_mobile.png';
import focusImg1 from './img_focus1.jpg';
import focusImg2 from './img_focus2.jpg';
import focusImg3 from './img_focus3.jpg';
import focusImg4 from './img_focus4.png';
import focusImg5 from './img_focus5.png';
import focusImg6 from './img_focus6.png';
import focusImg7 from './img_focus7.png';
import focusImg8 from './img_focus8.png';
import focusImg9 from './img_focus9.png';
import gmpImg from './img_gmp.png';
import dietImg1 from './img_diet1.jpg';
import dietImg2 from './img_diet2.jpg';
import dietImg3 from './img_diet3.jpg';
import dietImg4 from './img_diet4.png';
import dietImg5 from './img_diet5.png';
import dietImg6 from './img_diet6.png';
import dietDetailImg from './img_diet_detail.jpg';
import dietmemberImg1 from './img_diet_member_1.png';
import dietmemberImg2 from './img_diet_member_2.png';
import dietmemberImg3 from './img_diet_member_3.png';
import dietmemberImg4 from './img_diet_member_4.png';
import goldMImg1 from './img_m_gold1.png';
import goldMImg2 from './img_m_gold2.png';
import goldMImg3 from './img_m_gold3.png';
import goldImg1 from './img_gold1.png';
import goldImg2 from './img_gold2.png';
import goldImg3 from './img_gold3.png';
import logoSymbolImg from './img_logo_symbol.png';
import reviewImg from './img_review.jpg';
import reservationsImg from './img_reservations.jpg';
import noticesImg from './img_notices.jpg';
import faqsImg from './img_faqs.jpg';
import bigDietImg1 from './img_big_diet1.png';
import bigDietImg2 from './img_big_diet2.png';
import bigDietImg3 from './img_big_diet3.png';

export default {
  // icon
  mobileClose,
  mobileBtn,
  mobileArrow,
  arrowDownBlack,
  arrowDownGray,
  circleDown,
  arrowRight,
  arrowRightLong,
  arrowRightLong1,
  footerIcon1,
  footerIcon2,
  footerIcon3,
  footerIcon4,
  uncheck,
  check,
  landingIcon1,
  landingIcon2,
  icMemo,
  icDay,
  icTime,
  icTopButton,
  icTopButtonWhite,
  icFix,
  icWarning,
  icPagination1,
  icPagination2,
  icPagination3,
  icPagination4,
  icPlay,
  icStop,
  icModalClose,
  icModalGoogle,
  icModalKakao,
  icModalWarring,
  icGrowth1,
  icGrowth2,
  icGrowth3,
  icGrowth4,
  icGrowth5,
  icLink,
  icDiet1,
  icDiet2,
  icDiet3,
  icDiet4,
  icClear,
  icCallButton,
  icCountry,
  icGoldDiet1,
  icGoldDiet2,
  icGoldDiet3,
  icGoldDiet4,
  icGoldDiet5,
  icGoldDiet6,
  icGoldDiet7,
  icGoldDiet8,
  icGoldDiet9,
  icGoldDiet10,
  icGoldDiet11,
  icGoldDiet12,
  icDietMemo,

  // image
  logoImg,
  logoWhiteImg,
  logoGreenImg,
  logoGoldImg,
  footerLogo,
  landingImg,
  homeCalendarImg,
  homeImg1,
  homeImg2,
  homeImg3,
  homeImg4,
  introductionImg1,
  introductionImg2,
  introductionImg3,
  mDoctorImg1,
  doctorImg1,
  doctorImg2,
  doctorImg3,
  doctorImg4,
  doctorMbImg4,
  doctorImg5,
  doctorImg6,
  directionsImg,
  ceoImg1,
  ceoImg2,
  ceoImg3,
  ceoImg4,
  growthImg1,
  growthImg2,
  growthImg3,
  growthImg4,
  growthImg5,
  growthImg7,
  growthMobileImg,
  prostateImg1,
  prostateImg2,
  prostateImg3,
  prostateImg5,
  prostateImg6,
  prostateImg7,
  prostateImg8,
  prostateMobileImg,
  focusImg1,
  focusImg2,
  focusImg3,
  focusImg4,
  focusImg5,
  focusImg6,
  focusImg7,
  focusImg8,
  focusImg9,
  gmpImg,
  dietImg1,
  dietImg2,
  dietImg3,
  dietImg4,
  dietImg5,
  dietImg6,
  dietDetailImg,
  logoSymbolImg,
  reviewImg,
  reservationsImg,
  noticesImg,
  faqsImg,
  dietmemberImg1,
  dietmemberImg2,
  dietmemberImg3,
  dietmemberImg4,
  goldMImg1,
  goldMImg2,
  goldMImg3,
  goldImg1,
  goldImg2,
  goldImg3,
  bigDietImg1,
  bigDietImg2,
  bigDietImg3,
};
