const RouterPath = {
  slash: '/',
  signin: '/auth/signin',

  // SNS 로그인 콜백
  LoginCallbackGoogle: '/google-callback',
  LoginCallbackKakao: '/kakao-callback',

  // 에러
  Errors: '/errors',

  Landing: '/landing',

  // 메인페이지
  Home: '/home',

  // About
  Introduction: '/introduction',
  Doctors: '/doctors',
  Directions: '/directions',

  // 건강정보
  Growth: '/growth',
  Prostate: '/prostate',
  Focus: '/focus',
  Diet: '/diet',

  // 치료후기
  Review: '/review',
  ReviewDetail: '/review/detail',

  // 게시판
  Notices: '/notices',
  NoticeDetail: '/notice/detail',
  Faqs: '/faqs',
  Reservations: '/reservations',
  ReservationsWrtie: '/write',
  ReservationsClear: '/clear',

  // 약관
  TermPrivate: '/term/private',
};

export default RouterPath;
