import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { images } from '../../assets';
import { apiGetPopupList } from '../../api/RestAPI';
import POPUP_TYPE from '../../common/constants/PopupType';
import { handleError } from '../../utils/HandleError';
import { Utils } from '../../common';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';

export default React.memo(function Growth(props) {
  const navigate = useNavigate();

  const POPUP_DISABLED = 'popup_disable';
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');

  const videoId = '1AG9J5kMZ4g';
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 팝업
  const [activePopup, setActivePopup] = useState(0);
  const [popupList, setPopupList] = useState([]); // 팝업 리스트

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  const getPopupList = async () => {
    try {
      const { data } = await apiGetPopupList(POPUP_TYPE.CLINIC_HEIGHT.code);

      if (data) {
        const popups = data.data;

        // Local Storage
        let disabledList = localStorage.getItem(POPUP_DISABLED);
        disabledList = disabledList ? JSON.parse(disabledList) : [];

        // Local Storage 정보 O
        if (disabledList.length > 0) {
          // 유효하지 않은 Storage 데이터 제거 & 재설정
          disabledList = disabledList.filter(item => today === `${item.date}`);
          localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

          // 필터
          setPopupList(
            popups.filter(item => {
              return !disabledList.find(el => el.idx === item.popupIdx);
            }),
          );
        }
        // Local Storage 정보 X
        else {
          setPopupList(popups);
        }
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  const handleReviewClick = () => {
    navigate('/review');
  };

  // 팝업 출력
  const openPopup = activeIdx => {
    if (activeIdx < popupList.length) {
      const popup = popupList[activeIdx];

      Utils.openModal({
        type: MODAL_TYPES.PopupModal,
        data: {
          actionLink: popup.actionLink,
          imageName: popup.imageName,
          imagePath: popup.imagePath,
          mHeight: popup.mHeight,
          mWidth: popup.mWidth,
          orderNo: popup.orderNo,
          pcHeight: popup.pcHeight,
          pcWidth: popup.pcWidth,
          popupIdx: popup.popupIdx,
          hasNext: activeIdx < popupList.length - 1,
        },
        closeEvent: () => closePopup(activeIdx), // 닫기
        confirmEvent: () => disablePopup(activeIdx), // 하루동안 미출력
      });
    }
  };

  // 팝업 > 하루동안 미출력
  const disablePopup = activeIdx => {
    // Local Storage
    let disabledList = localStorage.getItem(POPUP_DISABLED);
    disabledList = disabledList ? JSON.parse(disabledList) : [];

    // 선택 팝업 미출력 처리
    disabledList.push({ idx: popupList[activeIdx].popupIdx, date: today });
    localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

    // 다음 팝업
    setActivePopup(activeIdx + 1);
  };

  // 팝업 > 닫기
  const closePopup = activeIdx => {
    setActivePopup(activeIdx + 1);
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  // 팝업 리스트 조회
  useEffect(() => {
    getPopupList();
  }, []);

  // 팝업 순차 출력
  useEffect(() => {
    if (popupList.length > 0) {
      openPopup(activePopup);
    }
    return () => {};
  }, [popupList, activePopup]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************
  return (
    <main id="growth">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.growthImg1} alt="메인 이미지" />
          <div className="main-text">
            <h2>골든타임</h2>
            <p className="text">키성장 빅데이터 연구센터</p>
          </div>
        </div>
      </section>
      <section className="contents-section1">
        <div className="contents-container inner">
          <div className="contents-text contents-iframe">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe width="100%" height="100%" src={videoUrl} frameBorder="0" />
            {/* <iframe width="1200" height="675" src={videoUrl} frameBorder="0" /> */}
          </div>
          <div className="contents-text">
            <div className="sub-title">
              <h2>
                키성장 고민, <br className="br-500" />
                골든힐이 답하다
              </h2>
            </div>
            <div className="sub-text">
              <p>
                또래보다 작은 우리 아이 키.
                <br /> 조금이라도 빨리, 1cm라도 더 키워 높은 자신감을 심어주고
                싶으시죠.
              </p>
              <p className="m-0">
                아이의 자신감 넘치는 미래를 위한 첫걸음,
                <br /> 밝은 내일을 위해{' '}
                <span>골든타임 키성장 빅데이터 연구센터</span>가 함께하겠습니다.
              </p>
            </div>
          </div>
          <div className="contents-text last-text">
            <div className="sub-title">
              <h2>
                내 아이는 얼마나 더 <br className="br-500" />클 수 있을까?
              </h2>
            </div>
            <div className="sub-text">
              <p className="text-bottom">
                많은 부모님의 마음 속에 있는 질문입니다.
                <br /> 한혁규 대표원장의 부모님도 한때는 같은 고민을 가졌습니다.
              </p>
              <p className="m-0">
                아버지가 171cm, 어머니가 160cm이었지만, 중학생이 되어도 또래보다
                작았기에 끊임없이 ‘우리 아이는 큰 키를 가질 수 있을까?’라는
                고민을 했습니다.
              </p>
            </div>
          </div>
          <div className="contents-img">
            <img src={images.growthImg2} alt="이미지 2" />
          </div>
        </div>
      </section>
      <section className="contents-section2">
        <div className="contents-container inner">
          <div className="contents-text">
            <p>
              3대를 잇는 한의 명가의 지혜와 비방으로
              <br /> 아들의 몸에 딱 맞는 성장치료를 해준 덕분에,
              <br /> 유전적 예상 키를 뛰어넘은 188cm의 어른으로 성장했습니다.
            </p>
          </div>
          <div className="contents-img">
            <img src={images.growthImg3} alt="이미지 3" />
          </div>
        </div>
      </section>
      <section className="contents-section3">
        <div className="contents-container inner">
          <div className="contents-text">
            <div className="sub-title">
              <h2>
                100년 전통에 <br className="br-500" />
                현대 과학을 더합니다
              </h2>
            </div>
            <div className="sub-text">
              <p>
                두 아이를 190cm에 가까운 키로 만든 성장치료.
                <br /> 4대를 이어온 100년 전통 한의학 명가의 비방에 실제 진료
                데이터의 과학적 분석과 현대적인 검사 및 진단을 더해, 더 많은
                아이들이 더욱 효과적인 성장치료를 누릴 수 있도록 발전시키고
                있습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section4">
        <div className="contents-container">
          <div className="contents-text">
            <h2>
              <span>
                내 아이의
                <br /> 소중한 시간을 놓치지 않는
              </span>
              <br />
              골.든.타.임. 키성장 빅데이터 연구센터
            </h2>
            <p className="text">
              하루라도 빨리. 적절한 시기에
              <br /> 우리 아이에게 꼭 맞는 성장치료를 받아야 키성장의 골든타임을
              놓치지 않을 수 있습니다.
            </p>
            <p className="text">
              <span style={{ color: '#DBA075', fontWeight: 500 }}>
                골든타임 키성장 빅데이터 연구센터
              </span>
              에서는
              <br /> 상담, 검사, 진료, 처방, 후속 케어까지 이어지는 모든 치료
              과정을 우리 아이에게 꼭 필요한{' '}
              <span style={{ color: '#FFF', fontWeight: 700 }}>
                100% 개인 맞춤형*
              </span>
              으로 받을 수 있습니다.
            </p>
            <div className="contents-text-box">
              <p>
                * 골든타임 키성장 빅데이터 연구센터 진료는 100% 개인
                맞춤형입니다.
              </p>
              <p className="sub-text">
                사전 예약제로만 진행되며 상담 예약이 필수입니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section5">
        <div className="contents-container inner">
          <div className="contents-text">
            <div className="contents-img">
              <img src={images.logoSymbolImg} alt="" />
            </div>
            <h2>
              골든타임 <br className="br-500" />
              키성장 빅데이터 연구센터는
              <br /> 기존 유전학의 한계를 넘어{' '}
              <span>후천적 성장 재생인자의 활성화</span>를 목표로 합니다
            </h2>
            <p>
              키가 클 수 있는 시간은 생각보다 길지 않을 수 있습니다.
              <br /> 중요한 시기에 더 효과적으로 클 수 있도록 1:1 집중케어가
              필요합니다.
            </p>
          </div>
          <div className="contents-box">
            <p className="title">
              골든타임
              <br className="br-500" /> 키성장 빅데이터 연구센터
              <br /> 1:1 집중케어
            </p>
            <ul className="contents-list">
              <li className="contents-item">
                <div className="contents-icon">
                  <img src={images.icGrowth1} alt="" />
                </div>
                <p>체계적이고 꼼꼼한 검사</p>
              </li>
              <li className="contents-item">
                <div className="contents-icon">
                  <img src={images.icGrowth2} alt="" />
                </div>
                <p>키성장 진료 상담</p>
              </li>
              <li className="contents-item">
                <div className="contents-icon">
                  <img src={images.icGrowth3} alt="" />
                </div>
                <p>
                  부모님과 함께
                  <br /> 키성장 계획 수립
                </p>
              </li>
              <li className="contents-item">
                <div className="contents-icon">
                  <img src={images.icGrowth4} alt="" />
                </div>
                <p>다양한 맞춤형 성장 치료</p>
              </li>
              <li className="contents-item">
                <div className="contents-icon">
                  <img src={images.icGrowth5} alt="" />
                </div>
                <p>키성장 한약 처방</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contents-section6">
        <div className="contents-container">
          <div className="contents-box inner">
            <h2>
              100년 전통 <br className="br-500" />
              한의명가의 비방과 <br className="br-500" />키 188cm 원장님의
              <br /> 실제 성장 경험을 바탕으로 한 건강하고 구체적인
              <br /> 우리 아이 <span>키성장 플랜</span>을 시작해 보세요
            </h2>
            <Button className="btn" onClick={handleReservationsClick}>
              상담 예약 신청하기 →
            </Button>
          </div>
        </div>
      </section>
      <section className="contents-section7">
        <div className="contents-container inner">
          <h2>
            골든타임 <br className="br-500" />
            키성장 빅데이터 연구센터 프로세스
          </h2>
          <ul className="contents-list">
            <li className="contents-item">
              <p className="number">STEP 1</p>
              <p className="title">진료 예약</p>
              <p>
                심도 깊은 검사 및 상담을 위해 모든 진료는 100% 예약제로
                운영합니다.
              </p>
            </li>
            <li className="contents-item">
              <p className="number">STEP 2</p>
              <p className="title">내원 접수, 문진표 작성</p>
              <p>
                아이와 내원 후, 정확한 진단과 치료를 위해 생활습관, 건강 상태를
                작성합니다.
              </p>
            </li>
            <li className="contents-item">
              <p className="number">STEP 3</p>
              <p className="title">기초 검사 및 특수 검사</p>
              <p>
                측정 장비로 아이의 키와 비만도, 근육량 등 기본 신체 정보를
                측정하고, 체질 검사를 포함한 7-10가지 특수 검사를 진행합니다.
              </p>
            </li>
            <li className="contents-item">
              <p className="number">STEP 4</p>
              <p className="title">한의사 진료 상담 및 세부 플랜 안내</p>
              <p>
                검사 결과를 바탕으로 아이의 상태, 질환, 환경에 맞는 체계적이고
                꼼꼼한 1:1 통합 진료를 부모님과 함께 진행합니다. 아이 맞춤형
                생활방법, 프로그램 주의사항 등을 세부적으로 안내합니다.
              </p>
            </li>
            <li className="contents-item">
              <p className="number">STEP 5</p>
              <p className="title">성장 치료</p>
              <p>
                아이의 상태에 필요한 성장호르몬 촉진 테라피를 진행합니다.
                (성장판 자극침, 저주파 치료, 성장추나, 성장판 자극 마사지 등
                다양한 맞춤 치료를 진행합니다)
              </p>
            </li>
            <li className="contents-item">
              <p className="number">STEP 6</p>
              <p className="title">한약 수령 및 후속 케어</p>
              <p>
                한약 복용 전 주의사항, 식습관 프로그램을 안내하고 수령 후 복용
                기간 동안 아이 상태를 밀착 관리하는 해피콜 케어를 진행합니다.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="contents-section8">
        <div className="contents-container inner">
          <div className="contents-box">
            <div className="contents-title">
              <div className="contents-icon">
                <img src={images.icMemo} alt="memo" />
              </div>
              <h2>
                골든타임 <br className="br-500" />
                키성장 빅데이터 연구센터 프로그램 후기
              </h2>
            </div>
            <Button
              className="btn-outline btn-outline-primary"
              onClick={handleReviewClick}
            >
              키성장 치료 후기 보기
            </Button>
          </div>
        </div>
      </section>
      <section className="contents-section9">
        <div className="contents-container">
          <div className="contents-box inner">
            <h2>
              내 아이에게 중요한
              <br />
              키성장의 <span>골든타임</span>을 꼭 잡으세요
            </h2>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton className="top-btn" />

      <Footer />
    </main>
  );
});
