/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { RouterPath } from '@common';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { images } from '../../assets';
import { Utils } from '../../common';
import { MODAL_TYPES } from '../Modal/GlobalModal';

export default React.memo(function Header({ type }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isTraditionMenuVisible, setIsTraditionMenuVisible] = useState(false);
  const [isCommunityMenuVisible, setIsCommunityMenuVisible] = useState(false);
  const [isTraditionHovered, setIsTraditionHovered] = useState(false);
  const [isCommunityHovered, setIsCommunityHovered] = useState(false);
  const [traditionArrowImg, setTraditionArrowImg] = useState(
    images.arrowDownBlack,
  );
  const [communityArrowImg, setCommunityArrowImg] = useState(
    images.arrowDownBlack,
  );
  const [isActiveTradition, setIsActiveTradition] = useState(false);
  const [isActiveCommunity, setIsActiveCommunity] = useState(false);

  // 메뉴와 sub-item에 대한 참조 생성
  const traditionMenuRef = useRef(null);
  const communityMenuRef = useRef(null);

  // 로그인 유무
  const auth =
    localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth'));
  const isAuthorized = auth && auth?.accessToken && auth?.refreshToken;

  const [isMobileTabVisible, setMobileTabVisible] = useState(false);
  const [isTraditionSubmenuVisible, setTraditionSubmenuVisible] =
    useState(false);
  const [isCommunitySubmenuVisible, setCommunitySubmenuVisible] =
    useState(false);
  const [headerClass, setHeaderClass] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  // 메뉴 표시 로직 개선
  const shouldShowMenu = isMenuVisible || isHovered;

  // 버튼 클릭 이벤트 핸들러
  const toggleMobileTab = () => {
    setMobileTabVisible(!isMobileTabVisible);
    // 모바일 탭의 상태에 따라 body의 overflow 스타일을 설정
    document.body.style.overflow = !isMobileTabVisible ? 'hidden' : 'auto';
  };

  const toggleTraditionSubmenu = () => {
    setTraditionSubmenuVisible(!isTraditionSubmenuVisible);
  };

  const toggleCommunitySubmenu = () => {
    setCommunitySubmenuVisible(!isCommunitySubmenuVisible);
  };

  // 링크 클릭 시 실행될 함수
  const closeTraditionSubmenu = () => {
    setMobileTabVisible(false);
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  // 스크롤 되면 header 색상 변경
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setHeaderClass('scrolled');
    } else {
      setHeaderClass('');
    }
  };

  // 로그인
  const login = () => {
    setMobileTabVisible(false);
    setTraditionSubmenuVisible(false);
    setCommunitySubmenuVisible(false);

    Utils.openModal({
      type: MODAL_TYPES.LoginModal,
      data: { pathname },
      closeEvent: () => {
        navigate(pathname);
      },
    });
  };

  // 로그아웃
  const logout = () => {
    // TODO ::: Confirm 모달로 대체 - "로그아웃 하시겠습니까"
    localStorage.removeItem('auth');
    setMobileTabVisible(false);
    setTraditionSubmenuVisible(false);
    setCommunitySubmenuVisible(false);
    navigate(RouterPath.Home);
  };

  // 메뉴 외부 클릭 감지 함수
  const handleClickOutside = event => {
    if (!traditionMenuRef.current?.contains(event.target)) {
      setIsTraditionMenuVisible(false);
      setIsActiveTradition(false); // 외부 클릭 시 활성 상태 초기화
    }
    if (!communityMenuRef.current?.contains(event.target)) {
      setIsCommunityMenuVisible(false);
      setIsActiveCommunity(false); // 외부 클릭 시 활성 상태 초기화
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // 클릭 이벤트 리스너 등록
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // 클린업 함수에서 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // 페이지가 변경될 때 마다 body의 overflow 스타일을 초기화
    document.body.style.overflow = 'auto';

    // 클릭 이벤트 리스너 등록
    document.addEventListener('mousedown', handleClickOutside);

    // 클린업 함수에서 이벤트 리스너 제거와 overflow 스타일 초기화
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [pathname]); // pathname이 변경될 때마다 실행

  // 기본 헤더
  if (type === 'basic') {
    return (
      <header id="header" className={headerClass}>
        <div className="header-container inner">
          <div className="logo" onClick={handleLogoClick}>
            <img src={images.logoImg} alt="logo" />
          </div>
          <nav className="header-nav">
            <ul className="header-list">
              <li
                className={`sub-item ${isActiveTradition ? 'active' : ''}`}
                onMouseEnter={() => {
                  setIsTraditionHovered(true);
                  setTraditionArrowImg(images.mobileArrow);
                }}
                onMouseLeave={() => {
                  setIsTraditionHovered(false);
                  if (!isActiveTradition) {
                    setTraditionArrowImg(images.arrowDownBlack);
                  }
                }}
                onClick={() => {
                  const newVisibility = !isTraditionMenuVisible;
                  setIsTraditionMenuVisible(newVisibility);
                  setIsActiveTradition(newVisibility); // 클릭 시 활성 상태 변경
                  setTraditionArrowImg(
                    newVisibility ? images.mobileArrow : images.arrowDownBlack,
                  );
                }}
                ref={traditionMenuRef}
              >
                <div
                  className={`sub-title ${isActiveTradition ? 'active' : ''}`}
                >
                  <p>100년 전통</p>
                  <div className="icon">
                    <img src={traditionArrowImg} alt="arrow" />
                  </div>
                </div>
                <div
                  className="menu"
                  style={{
                    display:
                      isTraditionMenuVisible || isTraditionHovered
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <ul className="inner">
                    <li>
                      <Link
                        to={RouterPath.Introduction}
                        onClick={closeTraditionSubmenu}
                      >
                        한의원 소개
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={RouterPath.Doctors}
                        onClick={closeTraditionSubmenu}
                      >
                        의료진 소개
                      </Link>
                    </li>
                    <li>
                      {' '}
                      <Link
                        to={RouterPath.Directions}
                        onClick={closeTraditionSubmenu}
                      >
                        지점 안내
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <ul className="sub-header-list">
                <li className="header-item">
                  <Link to={RouterPath.Growth} onClick={closeTraditionSubmenu}>
                    키성장
                  </Link>
                </li>
                <li className="header-item">
                  <Link
                    to={RouterPath.Prostate}
                    onClick={closeTraditionSubmenu}
                  >
                    전립선
                  </Link>
                </li>
                <li className="header-item">
                  <Link to={RouterPath.Focus} onClick={closeTraditionSubmenu}>
                    집중력
                  </Link>
                </li>
                <li className="header-item">
                  <Link to={RouterPath.Diet} onClick={closeTraditionSubmenu}>
                    다이어트
                  </Link>
                </li>

                <li className="header-item">
                  <Link to={RouterPath.Review} onClick={closeTraditionSubmenu}>
                    치료 후기
                  </Link>
                </li>
              </ul>
              <li
                className={`sub-item ${isActiveCommunity ? 'active' : ''}`}
                onMouseEnter={() => {
                  setIsCommunityHovered(true);
                  setCommunityArrowImg(images.mobileArrow);
                }}
                onMouseLeave={() => {
                  setIsCommunityHovered(false);
                  if (!isActiveCommunity) {
                    setCommunityArrowImg(images.arrowDownBlack);
                  }
                }}
                onClick={() => {
                  const newVisibility = !isCommunityMenuVisible;
                  setIsCommunityMenuVisible(newVisibility);
                  setIsActiveCommunity(newVisibility); // 클릭 시 활성 상태 변경
                  setCommunityArrowImg(
                    newVisibility ? images.mobileArrow : images.arrowDownBlack,
                  );
                }}
                ref={communityMenuRef}
              >
                <div
                  className={`sub-title ${isActiveCommunity ? 'active' : ''}`}
                >
                  <p>게시판</p>
                  <div className="icon">
                    <img src={communityArrowImg} alt="arrow" />
                  </div>
                </div>
                <div
                  className="menu"
                  style={{
                    display:
                      isCommunityMenuVisible || isCommunityHovered
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <ul className="inner-right">
                    <li>
                      <Link
                        to={RouterPath.Reservations}
                        onClick={closeTraditionSubmenu}
                      >
                        상담 예약 신청
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={RouterPath.Faqs}
                        onClick={closeTraditionSubmenu}
                      >
                        자주 묻는 질문
                      </Link>
                    </li>
                    <li>
                      {' '}
                      <Link
                        to={RouterPath.Notices}
                        onClick={closeTraditionSubmenu}
                      >
                        공지사항
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
          <div className="header-box">
            <Button
              variant="primary"
              size="sm"
              onClick={isAuthorized ? logout : login}
              className="btn-outline-primary"
            >
              {isAuthorized ? '로그아웃' : '로그인'}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleReservationsClick}
            >
              상담예약
            </Button>
            <div
              className={`btn-mobile ${isMobileTabVisible ? 'open' : ''}`}
              onClick={toggleMobileTab}
            >
              <span className="menu-line" />
              <span className="menu-line" />
              <span className="menu-line" />
            </div>
          </div>

          {/* 조건부 렌더링으로 모바일 탭 창 표시 */}
          {isMobileTabVisible && (
            <div
              className={`mobile-tab ${isMobileTabVisible ? 'visible' : ''}`}
            >
              {/* 모바일 메뉴 */}
              <ul className="menu">
                <li className="menu-item">
                  <div className="menu-title" onClick={toggleTraditionSubmenu}>
                    <div className="menu-title-box">
                      100년 전통
                      <div className="menu-icon">
                        <img
                          src={images.mobileArrow}
                          alt="mobile arrow"
                          className={`rotate-icon ${
                            isTraditionSubmenuVisible ? 'open' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  {isTraditionSubmenuVisible && (
                    <ul className="submenu" onClick={e => e.stopPropagation()}>
                      <li>
                        <Link
                          to={RouterPath.Introduction}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          한의원 소개
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Doctors}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          의료진 소개
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Directions}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          지점 안내
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Growth}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    키성장
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Focus}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    집중력
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Diet}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    다이어트
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Prostate}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    전립선
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Review}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    치료 후기
                  </Link>
                </li>
                <li className="menu-item">
                  <div className="menu-title" onClick={toggleCommunitySubmenu}>
                    <div className="menu-title-box">
                      게시판
                      <div className="menu-icon">
                        <img
                          src={images.mobileArrow}
                          alt="mobile arrow"
                          className={`rotate-icon ${
                            isCommunitySubmenuVisible ? 'open' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  {isCommunitySubmenuVisible && (
                    <ul className="submenu" onClick={e => e.stopPropagation()}>
                      <li>
                        <Link
                          to={RouterPath.Reservations}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          상담 예약 신청
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Faqs}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          자주 묻는 질문
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Notices}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          공지사항
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="menu-item">
                  <span onClick={isAuthorized ? logout : login}>
                    {isAuthorized ? '로그아웃' : '로그인'}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    );
  }

  // 랜딩 헤더
  if (type === 'special') {
    return (
      <header id="header" className={`${headerClass} header-landing`}>
        <div className="header-container inner">
          <div className="logo" onClick={handleLogoClick}>
            <img src={images.logoImg} alt="logo" />
          </div>
          <div className="header-box">
            <Button
              variant="primary"
              size="sm"
              onClick={isAuthorized ? logout : login}
              className="btn-outline-primary"
            >
              로그인
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleReservationsClick}
            >
              상담예약
            </Button>
            <div
              className={`btn-mobile ${isMobileTabVisible ? 'open' : ''}`}
              onClick={toggleMobileTab}
            >
              <span className="menu-line" />
              <span className="menu-line" />
              <span className="menu-line" />
            </div>
          </div>

          {/* 조건부 렌더링으로 모바일 탭 창 표시 */}
          {isMobileTabVisible && (
            <div
              className={`mobile-tab ${isMobileTabVisible ? 'visible' : ''}`}
            >
              {/* 모바일 메뉴 */}
              <ul className="menu">
                <li className="menu-item">
                  <div className="menu-title" onClick={toggleTraditionSubmenu}>
                    <div className="menu-title-box">
                      100년 전통
                      <div className="menu-icon">
                        <img
                          src={images.mobileArrow}
                          alt="mobile arrow"
                          className={`rotate-icon ${
                            isTraditionSubmenuVisible ? 'open' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  {isTraditionSubmenuVisible && (
                    <ul className="submenu" onClick={e => e.stopPropagation()}>
                      <li>
                        <Link
                          to={RouterPath.Introduction}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          한의원 소개
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Doctors}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          의료진 소개
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Directions}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          지점 안내
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Growth}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    키성장
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Focus}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    집중력
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Diet}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    다이어트
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Prostate}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    전립선
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to={RouterPath.Review}
                    style={{ color: '#DBA075' }}
                    onClick={closeTraditionSubmenu}
                  >
                    치료 후기
                  </Link>
                </li>
                <li className="menu-item">
                  <div className="menu-title" onClick={toggleCommunitySubmenu}>
                    <div className="menu-title-box">
                      게시판
                      <div className="menu-icon">
                        <img
                          src={images.mobileArrow}
                          alt="mobile arrow"
                          className={`rotate-icon ${
                            isCommunitySubmenuVisible ? 'open' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  {isCommunitySubmenuVisible && (
                    <ul className="submenu" onClick={e => e.stopPropagation()}>
                      <li>
                        <Link
                          to={RouterPath.Reservations}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          상담 예약 신청
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Faqs}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          자주 묻는 질문
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={RouterPath.Notices}
                          style={{ color: '#DBA075' }}
                          onClick={closeTraditionSubmenu}
                        >
                          공지사항
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="menu-item">
                  {/* <Link to="/landing" style={{ color: '#DBA075' }}> */}
                  <span onClick={isAuthorized ? logout : login}>
                    {isAuthorized ? '로그아웃' : '로그인'}
                  </span>
                  {/* </Link> */}
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    );
  }
});
