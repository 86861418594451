/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import {
  NetworkException,
  InvalidUserException,
  AccessDeniedException,
} from '../common/exceptions';
import Utils from './Utils';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'http://localhost:4001';

// ***********************************************************
// HTTP Instance
// ***********************************************************
const api = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

// ***********************************************************
// HTTP Interceptor > Request
// ***********************************************************
api.interceptors.request.use(
  async config => {
    const requestConfig = config;

    try {
      Utils.showLoadingSpinner();

      if (localStorage.getItem('auth')) {
        const { accessToken } = JSON.parse(localStorage.getItem('auth'));
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (err) {
      Utils.hideLoadingSpinner();
      console.log('[ ERROR ] HTTP > Interceptor > Request ::: ', err);
    }

    return config;
  },
  error => {
    Utils.hideLoadingSpinner();
    return Promise.reject(error);
  },
);

// ***********************************************************
// HTTP Interceptor > Response
// ***********************************************************
api.interceptors.response.use(
  response => {
    Utils.hideLoadingSpinner();
    return response;
  },
  async error => {
    console.log(
      '[ ERROR ] HTTP > Interceptor > Response ::: ',
      error?.response?.data?.message,
    );

    Utils.hideLoadingSpinner();

    // 커넥션 에러
    if (!error?.response)
      throw new NetworkException('서버와의 통신을 확인할 수 없습니다.');

    const {
      // data,
      config,
      response: {
        data: { code, message },
      },
    } = error;
    const originalRequest = config;

    // ----------------------------------------
    // 에러 후처리
    // ----------------------------------------

    // 에러코드 제거
    let replacedMessage = message;

    if (replacedMessage.includes(']')) {
      replacedMessage = replacedMessage.split(']')[1].trim();
    }

    // [ 토큰 에러 ] 기간 만료 > Access Token 재발급
    if (`${code}` === '1001' && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const { refreshToken } = auth;
        axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;

        const { data } = await axios.get(`${baseURL}/api/v1/auth/access-token`);
        if (data) {
          const newAccessToken = data.data.accessToken;
          localStorage.setItem(
            'auth',
            JSON.stringify({
              ...auth,
              accessToken: newAccessToken,
            }),
          );
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        localStorage.removeItem('auth');
        return Promise.reject(new InvalidUserException(replacedMessage));
      }
    }
    // [ 토큰 에러 ] 탈퇴 계정
    else if (`${code}` === '1108') {
      localStorage.removeItem('auth');
      return Promise.reject(new AccessDeniedException(replacedMessage));
    }
    // [ 토큰 에러 ] 유효하지 않은 권한
    else if (
      `${code}` === '1002' ||
      `${code}` === '1003' ||
      `${code}` === '1098'
    ) {
      return Promise.reject(new AccessDeniedException(replacedMessage));
    }
    // [ 토큰 에러 ] 잘못된 토큰 > 로그아웃 처리
    else if (
      `${code}` === '1004' ||
      `${code}` === '1005' ||
      `${code}` === '1006' ||
      `${code}` === '1099'
    ) {
      localStorage.removeItem('auth');
      return Promise.reject(new InvalidUserException(replacedMessage));
    }

    // [ 그 외 에러 ]
    return Promise.reject(new NetworkException(replacedMessage));
  },
);

export default api;
