import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import KakaoMap from '../../components/Kakao/KakaoMap';
import { images } from '../../assets';

export default React.memo(function Directions(props) {
  const navigate = useNavigate();

  // Redux Store 조회
  const info = useSelector(state => {
    return state.info;
  });

  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  return (
    <main id="directions">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.directionsImg} alt="메인 이미지" />
        </div>
      </section>
      <section className="contents-section">
        <div className="contents-container">
          <div className="contents-list contents-3">
            <div className="contents-container inner">
              <h2>진료 시간</h2>
              <div className="directions-box">
                <div className="directions-item">
                  <p className="title" style={{ minWidth: 115 }}>
                    월요일~금요일
                  </p>
                  <p>
                    10:00-19:00
                    <ul>
                      <li className="text-list">휴게시간 13:00-14:00</li>
                      <li className="text-list">접수마감 18:00</li>
                    </ul>
                  </p>
                </div>
                <div className="directions-item">
                  <p className="title m-title width-78">토요일</p>
                  <p>
                    10:00-14:00 <p>(휴게시간 없이 진료합니다)</p>
                  </p>
                </div>
                <div className="directions-item m-0">
                  <p className="title m-title">일요일, 공휴일</p>
                  <p>휴진</p>
                </div>
              </div>
              <ul className="directions-info">
                <li>
                  골든힐 한의원의 모든 진료는 100% 예약제로 진행
                  <br className="br-500" />
                  됩니다. 방문 전 상담 예약을 부탁드립니다.
                </li>
                <li>일요일, 공휴일은 휴진입니다.</li>
              </ul>
            </div>
          </div>
          <div className="contents-box inner">
            <div className="contents-list">
              <h2>오시는 길</h2>
              <div className="directions-item directions-flex">
                <p className="title" style={{ minWidth: 81 }}>
                  도로명 주소
                </p>
                <p style={{ maxWidth: 334 }}>
                  {info?.addressNew ||
                    '서울특별시 강남구 도산대로 409 S&S TOWER 2층 골든힐 한의원'}
                </p>
              </div>
              <div className="directions-item detail directions-flex">
                <p className="title" style={{ minWidth: 81 }}>
                  지번 주소
                </p>
                <p style={{ maxWidth: 334 }}>
                  {info?.addressOld ||
                    '서울특별시 강남구 청담동 87-4 S&S TOWER 2층 골든힐 한의원'}
                </p>
              </div>
              <div className="directions-detail">
                <div className="directions-item sub-detail">
                  <p className="title" style={{ minWidth: 81 }}>
                    대표 전화
                  </p>
                  <p>{info?.hp || '02-545-1925	'}</p>
                </div>
                <div className="directions-item">
                  <p className="title" style={{ minWidth: 81 }}>
                    팩스
                  </p>
                  <p>{info?.pax || '0504-054-1925'}</p>
                </div>
              </div>
            </div>
            <div className="contents-list contents-2">
              <h2>대중교통 이용 및 주차 안내</h2>
              <div className="directions-item">
                <p className="title">지하철역에서 오시는 방법</p>
                <ul className="directions-info">
                  <li>
                    수인분당선 <span>&apos;압구정로데오&apos;</span> 역 4번출구
                    (도보 5분)
                  </li>
                  <li>
                    7호선 <span>&apos;강남구청&apos;</span> 역 3-1번 출구 (도보
                    10분)
                  </li>
                </ul>
              </div>
              <div className="directions-item">
                <p className="title">
                  버스로
                  <br className="br-pc-1024" /> 오시는 방법
                </p>
                <ul className="directions-info">
                  <li>간선 402, 141</li>
                  <li>좌석 4412</li>
                  <li className="last-style">
                    <span>&apos;일지아트홀&apos;</span> 정류장에서 하차 (도보
                    3분)
                  </li>
                </ul>
              </div>
              <div className="directions-item m-0">
                <p className="title">주차</p>
                <p>발렛 파킹 가능 (2시간: 5,000원)</p>
              </div>
            </div>
          </div>
          <div className="map inner">
            {/* 지도 */}
            <KakaoMap />
          </div>
          <div className="btn-box">
            <Button
              variant="primary"
              size="lg"
              onClick={handleReservationsClick}
            >
              상담 예약 신청하기 →
            </Button>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
