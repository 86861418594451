/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Utils
import Utils from '../../utils/Utils';
import { images } from '../../assets';

const BasicModal = () => {
  const { modalTitle, modalBody, closeEvent, confirmEvent } = useSelector(
    state => state.modal,
  );

  return (
    <Container className="basic-modal">
      {/* 닫기 버튼 */}
      <div
        onClick={() => Utils.closeModal({ closeEvent })}
        className="close-btn"
      >
        <img src={images.icModalClose} alt="close" />
      </div>
      <div className="basic-container">
        {/* 타이틀 */}
        <h2>{modalTitle}</h2>
        {/* 본문 */}
        <p className="text">{modalBody}</p>
      </div>
    </Container>
  );
};

const Container = styled.section``;

export default BasicModal;
