/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Constants
import { RouterPath } from '@common';
import AVAILABLE_TIME_TYPE from '../../common/constants/AvailableTimeType';

// Components
import { Button } from 'react-bootstrap';
import { CustomSelect } from '../../components';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';

// API
import { apiPostReservationSend } from '../../api/RestAPI';

// Util
import Utils from '../../utils/Utils';
import { handleError } from '../../utils/HandleError';

// Image
import { images } from '../../assets';

// 예약 가능시간 리스트
const AVAILABLE_TIME_LIST = Object.values(AVAILABLE_TIME_TYPE);

// ***********************************************************
// [ 게시판 > 상담예약 ]
// ***********************************************************
export default React.memo(function ReservationsWrite(props) {
  const navigate = useNavigate();

  // 이전 페이지 동의 유무
  const location = useLocation();
  const isAgreed = location.state?.isAgreed;
  const nvid = localStorage.getItem('goldenHillNvid');
  const nvidExpire = localStorage.getItem('goldenHillNvidExpire');

  // TODO ::: 잘못된 접근 > 모달 & 뒤로가기
  if (!isAgreed) alert('잘못된 접근입니다.');

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  const [isValidForm, setIsValidForm] = useState(false); // Form 유효성 검증
  const [reservationForm, setReservationForm] = useState({
    bookingName: undefined,
    bookingPhonePrefix: PHONE_NUMBER_PREFIX[0].value,
    bookingPhoneNumber: undefined,
    bookingDate: '',
    bookingTime: '',
    availableTime: undefined,
    bookingMemo: '',
  });

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 신청 완료 후처리 ( 완료 페이지 이동 )
  const moveToClear = () => {
    navigate(RouterPath.ReservationsClear, { state: { isConfirmed: true } });
  };

  // Input > Text
  const inputTextHandler = e => {
    const fieldName = e.target.name;
    let fieldValue = e.target.value;

    if (fieldName === 'bookingPhoneNumber') {
      fieldValue = fieldValue.replace(/[^0-9]/g, ''); // 전화번호 > 숫자만 입력
    }

    setReservationForm({ ...reservationForm, [fieldName]: fieldValue });
  };

  // Input > Select
  const selectHandler = (fieldName, option) => {
    let fieldValue;

    switch (fieldName) {
      case 'bookingPhonePrefix': // 전화번호 식별자
        fieldValue = PHONE_NUMBER_PREFIX.find(
          item => item.value === option.value,
        ).value;
        break;
      case 'availableTime': // 상담가능시간
        fieldValue = AVAILABLE_TIME_LIST.find(
          item => item.value === option.value,
        ).value;
        break;
      default:
        break;
    }

    setReservationForm({ ...reservationForm, [fieldName]: fieldValue });
  };

  // Form Null 검증
  const validateNotNull = () => {
    if (!reservationForm.bookingName) {
      return false;
    }
    if (!reservationForm.bookingPhonePrefix) {
      return false;
    }
    if (!reservationForm.bookingPhoneNumber) {
      return false;
    }
    if (!reservationForm.availableTime) {
      return false;
    }

    return true;
  };

  // 에러 라벨 출력
  const displayErrorLabel = () => {
    setReservationForm({
      ...reservationForm,
      bookingName:
        reservationForm.bookingName === undefined
          ? ''
          : reservationForm.bookingName,
      bookingPhoneNumber:
        reservationForm.bookingPhoneNumber === undefined
          ? ''
          : reservationForm.bookingPhoneNumber,
      availableTime:
        reservationForm.availableTime === undefined
          ? ''
          : reservationForm.availableTime,
    });
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 상담예약
  const postReservationSend = async () => {
    let nvidString = null;

    // 전화번호 전처리
    let phoneNumber = `${reservationForm.bookingPhonePrefix}${reservationForm.bookingPhoneNumber}`;
    phoneNumber = Utils.addHypenToPhoneNumber(phoneNumber);

    // 데이터 재검증 - 전화번호
    if (!Utils.isValidPhoneNumber(phoneNumber)) {
      Utils.openModal({ body: '전화번호를 확인해주세요.' });
      return;
    }

    // kid expire 기간 체크 (현재 7일)
    if (
      nvid &&
      nvidExpire &&
      Utils.getTimeDifference(nvidExpire) < 7 * 1000 * 3600 * 24
    ) {
      nvidString = nvid;
    } else {
      // localStorage.setItem('goldenHillNvid', null);
      // localStorage.setItem('goldenHillNvidExpire', null);
    }

    try {
      const params = {
        bookingName: reservationForm.bookingName,
        bookingPhoneNumber: phoneNumber,
        availableTime: reservationForm.availableTime,
        bookingMemo: reservationForm.bookingMemo,
        kid: nvidString,
      };

      const { data } = await apiPostReservationSend(params);

      if (data) {
        Utils.closeModal({ closeEvent: moveToClear });
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    const isValid = validateNotNull();
    setIsValidForm(isValid);
  }, [reservationForm]);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="reservations-write">
      <Header type="basic" />
      {/* 예약 포멧 */}
      <section className="main-section">
        <div className="main-img">
          <img src={images.reservationsImg} alt="메인 이미지" />
        </div>
      </section>
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-list">
            {/* 안내 */}
            <div className="contents-title">
              <h2>상담 예약 신청하기</h2>
              <p>이름, 전화번호, 상담가능시간, 상담내용을 입력해주세요.</p>
            </div>

            <div className="contents-item">
              <div className="input-box">
                {/* 입력 > 이름 */}
                <input
                  type="text"
                  name="bookingName"
                  placeholder="이름"
                  value={reservationForm.bookingName}
                  onChange={inputTextHandler}
                  maxLength={10}
                />
                {reservationForm.bookingName !== undefined &&
                  !reservationForm.bookingName && (
                    <p className="pb-1 text-red">· 이름을 적어주세요.</p>
                  )}

                <div className="sub-input">
                  {/* 입력 > 전화번호 식별자 */}
                  <CustomSelect
                    className="select"
                    options={PHONE_NUMBER_PREFIX}
                    value={PHONE_NUMBER_PREFIX.find(
                      item => item.value === reservationForm.bookingPhonePrefix,
                    )}
                    onChange={option =>
                      selectHandler('bookingPhonePrefix', option)
                    }
                    placeholder="식별번호"
                  />

                  {/* 입력 > 전화번호 */}
                  <input
                    type="text"
                    name="bookingPhoneNumber"
                    placeholder="휴대폰번호 7자리 이상"
                    value={reservationForm.bookingPhoneNumber}
                    onChange={inputTextHandler}
                    maxLength={8}
                  />
                </div>
                {reservationForm.bookingPhoneNumber !== undefined &&
                  (!reservationForm.bookingPhoneNumber ||
                    reservationForm.bookingPhoneNumber.length < 7) && (
                    <p className="pb-1 text-red">
                      · 휴대폰번호 7자리 이상을 선택해주세요.
                    </p>
                  )}

                {/* 입력 > 상담가능시간 */}
                <CustomSelect
                  className="select"
                  options={AVAILABLE_TIME_LIST}
                  value={AVAILABLE_TIME_LIST.find(
                    item => item.value === reservationForm.availableTime,
                  )}
                  onChange={option => selectHandler('availableTime', option)}
                  placeholder="상담 가능 시간 선택"
                />
                {reservationForm.availableTime !== undefined &&
                  !reservationForm.availableTime && (
                    <p className="pb-1 text-red">
                      · 상담 가능 시간을 선택해주세요.
                    </p>
                  )}

                {/* 입력 > 상담내용 */}
                <textarea
                  className="text-area"
                  name="bookingMemo"
                  placeholder="희망 진료 분야 및 상담 내용을 여기에 적어주세요."
                  value={reservationForm.bookingMemo}
                  onChange={inputTextHandler}
                  maxLength={400}
                />
              </div>
              {/* 상담 신청하기 */}
              <div className="btn-box">
                <Button
                  size="lg"
                  style={{
                    backgroundColor: isValidForm ? '#DBA075' : '#D6D6D6',
                    border: 'none',
                    color: '#FFF',
                  }}
                  // disabled={!isValidForm}
                  onClick={() => {
                    // 예약 신청
                    if (isValidForm) {
                      Utils.openModal({
                        type: MODAL_TYPES.ConfirmModal,
                        title: '상담 예약 신청',
                        body: '작성하신 내용을 등록하시겠습니까?',
                        confirmEvent: postReservationSend,
                      });
                    }
                    // 에러 라벨 출력
                    else {
                      displayErrorLabel();
                    }
                  }}
                >
                  상담 신청하기
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});

// 전화번호 식별자 리스트
const PHONE_NUMBER_PREFIX = [
  {
    label: '010',
    value: '010',
  },
  {
    label: '011',
    value: '011',
  },
];
