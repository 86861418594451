/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScrollTop from './components/ScrollTop'; // 예시 경로, 실제 경로에 맞게 수정해야 함
import App from './App';
import ErrorBoundary from './utils/ErrorBoundary';

import './index.scss';
import { persistor, store } from './store';
import GlobalModal from './components/Modal/GlobalModal';

const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root'));

window.onload = () => {
  const lang = window.navigator.language;
  render();
};

function render() {
  root.render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={<div>로딩...</div>} persistor={persistor}>
            <BrowserRouter>
              <HelmetProvider>
                <GlobalModal />
                <ScrollTop />
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>,
  );
}
