import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { parse, format } from 'date-fns';

// Constants
import { RouterPath } from '@common';

// Components
import { Button } from 'react-bootstrap';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiGetReviewDetail } from '../../api/RestAPI';

// Util
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

// Image
import { images } from '../../assets';

// ***********************************************************
// [ 치료후기 > 리스트 > 상세 ]
// ***********************************************************
export default React.memo(function ReviewDetail(props) {
  const navigate = useNavigate();

  // 조회 리뷰 Index
  const params = useParams();
  const reviewIdx = params?.idx;

  // 잘못된 접근 > 모달 & 뒤로가기
  if (!reviewIdx) {
    Utils.openModal({ body: '잘못된 접근입니다.' });
  }

  // 이전 페이지 정보
  const location = useLocation();
  const prevPage = location.state?.page || 1;

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 리뷰 상세
  const [reviewDetail, setReviewDetail] = useState({
    reviewIdx: '',
    categoryCode: '',
    categoryName: '',
    thumbnailPath: '',
    reviewTitle: '',
    reviewBody: '',
    reviewFiles: [],
    regDate: '',
    regTime: '',
    // visitDate: '',
    // visitTime: '',
  });

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 이전 목록으로
  const moveToList = () => {
    navigate(`${RouterPath.Review}?page=${prevPage}`);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 공지사항 상세
  const getReviewDetail = async () => {
    try {
      const { data } = await apiGetReviewDetail(reviewIdx);

      if (data) {
        const info = data.data;

        // const visitDateTime = parse(
        //   info.visitDate,
        //   'yyyy-MM-dd HH:mm:ss.S',
        //   new Date(),
        // );
        const regDateTime = parse(
          info.regDate,
          'yyyy-MM-dd HH:mm:ss.S',
          new Date(),
        );

        setReviewDetail({
          reviewIdx: info.reviewIdx,
          categoryCode: info.categoryCode,
          categoryName: info.categoryName,
          thumbnailPath: info.thumbnailPath,
          reviewTitle: info.reviewTitle,
          reviewBody: info.reviewBody,
          reviewFiles: [...info.reviewFiles],
          regDate: format(regDateTime, 'yyyy-MM-dd'),
          regTime: format(regDateTime, 'HH:mm'),
          // visitDate: format(visitDateTime, 'yyyy-MM-dd'),
          // visitTime: format(visitDateTime, 'HH:mm'),
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getReviewDetail();
  }, []);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="review-detail">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.reviewImg} alt="메인 이미지" />
        </div>
      </section>
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-box">
            {/* 페이지 라벨 */}
            <h2>치료후기</h2>
            <p className="text">
              골든힐 한의원을 통해 오늘 더 건강한 삶을 찾은 분들의 이야기입니다
            </p>

            {/* 타이틀 */}
            <div className="contents-title">
              <div className="tab">{reviewDetail.categoryName || ''}</div>
              <p className="title">{reviewDetail.reviewTitle || ''}</p>
            </div>

            {/* 방문일자 */}
            <div className="contents-day">
              <div className="day">
                <div className="icon">
                  <img src={images.icDay} alt="" />
                </div>
                <p>{reviewDetail.regDate || ''}</p>
              </div>
              {/* <div className="day">
                <div className="icon">
                  <img src={images.icTime} alt="" />
                </div>
                <p>{reviewDetail.regTime || ''}</p>
              </div> */}
            </div>

            {/* 본문 */}
            <div className="contents-info">
              {/* 이미지 */}
              {reviewDetail.reviewFiles.length > 0 &&
                reviewDetail.reviewFiles.map((file, index) => {
                  return (
                    <div
                      key={`review-detail-file-${index}`}
                      className="contents-img"
                    >
                      <img
                        src={
                          file?.uploadedFilePath
                            ? `${process.env.REACT_APP_STORAGE_URL}/${file.uploadedFilePath}`
                            : images.growthImg5
                        }
                        alt=""
                      />
                    </div>
                  );
                })}
              {/* 텍스트 */}
              <p>{reviewDetail.reviewBody}</p>
            </div>

            {/* 목록으로 */}
            <Button
              className="btn-outline btn-outline-primary"
              onClick={moveToList}
            >
              목록 보기
            </Button>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      <Footer />
    </main>
  );
});
