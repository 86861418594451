import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import { images } from '../../assets';
import TopButton from '../../components/TopButton/TopButton';
import { handleError } from '../../utils/HandleError';
import { Utils } from '../../common';
import { MODAL_TYPES } from '../../components/Modal/GlobalModal';
import { apiGetPopupList } from '../../api/RestAPI';
import POPUP_TYPE from '../../common/constants/PopupType';

export default React.memo(function Diet(props) {
  const navigate = useNavigate();

  const POPUP_DISABLED = 'popup_disable';
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  // 팝업
  const [activePopup, setActivePopup] = useState(0);
  const [popupList, setPopupList] = useState([]); // 팝업 리스트

  // ***********************************************************
  // [ API ]
  // ***********************************************************
  const getPopupList = async () => {
    try {
      const { data } = await apiGetPopupList(POPUP_TYPE.CLINIC_DIET.code);

      if (data) {
        const popups = data.data;

        // Local Storage
        let disabledList = localStorage.getItem(POPUP_DISABLED);
        disabledList = disabledList ? JSON.parse(disabledList) : [];

        // Local Storage 정보 O
        if (disabledList.length > 0) {
          // 유효하지 않은 Storage 데이터 제거 & 재설정
          disabledList = disabledList.filter(item => today === `${item.date}`);
          localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

          // 필터
          setPopupList(
            popups.filter(item => {
              return !disabledList.find(el => el.idx === item.popupIdx);
            }),
          );
        }
        // Local Storage 정보 X
        else {
          setPopupList(popups);
        }
      }
    } catch (e) {
      handleError(e);
    }
  };
  // ***********************************************************
  // [ Util ]
  // ***********************************************************
  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  const handleReviewClick = () => {
    navigate('/review');
  };
  // 팝업 출력
  const openPopup = activeIdx => {
    if (activeIdx < popupList.length) {
      const popup = popupList[activeIdx];

      Utils.openModal({
        type: MODAL_TYPES.PopupModal,
        data: {
          actionLink: popup.actionLink,
          imageName: popup.imageName,
          imagePath: popup.imagePath,
          mHeight: popup.mHeight,
          mWidth: popup.mWidth,
          orderNo: popup.orderNo,
          pcHeight: popup.pcHeight,
          pcWidth: popup.pcWidth,
          popupIdx: popup.popupIdx,
          hasNext: activeIdx < popupList.length - 1,
        },
        closeEvent: () => closePopup(activeIdx), // 닫기
        confirmEvent: () => disablePopup(activeIdx), // 하루동안 미출력
      });
    }
  };

  // 팝업 > 하루동안 미출력
  const disablePopup = activeIdx => {
    // Local Storage
    let disabledList = localStorage.getItem(POPUP_DISABLED);
    disabledList = disabledList ? JSON.parse(disabledList) : [];

    // 선택 팝업 미출력 처리
    disabledList.push({ idx: popupList[activeIdx].popupIdx, date: today });
    localStorage.setItem(POPUP_DISABLED, JSON.stringify(disabledList));

    // 다음 팝업
    setActivePopup(activeIdx + 1);
  };

  // 팝업 > 닫기
  const closePopup = activeIdx => {
    setActivePopup(activeIdx + 1);
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  // 팝업 리스트 조회
  useEffect(() => {
    getPopupList();
  }, []);

  // 팝업 순차 출력
  useEffect(() => {
    if (popupList.length > 0) {
      openPopup(activePopup);
    }
    return () => {};
  }, [popupList, activePopup]);

  // ***********************************************************
  // [ return ]
  // ***********************************************************
  return (
    <main id="diet">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.dietImg1} alt="메인 이미지" />
          <div className="main-text">
            <h2>골든밸런스</h2>
            <p className="text">다이어트 센터</p>
          </div>
        </div>
        <div className="main-contents-text inner">
          <h2>
            완벽한 바디핏 <br />
            <span className="main-contents-special-text">포.기.하.지.마.</span>
          </h2>
        </div>
      </section>
      <section className="contents-section1">
        <div className="contents-container inner">
          <div className="contents-box1">
            <h2>다이어트 No.1</h2>
            <p>
              생체 빅데이터
              <br />
              다이어트 프로그램
            </p>
          </div>
          <div className="contents-box2">
            <div className="info-box">
              <div className="info-title">고객 만족도</div>
              <h2>
                9.7<span>점</span>
              </h2>
              <p>
                골든밸런스 다이어트 프로그램
                <br />
                경험자 만족도 평균
                <br />
                (10점 만점 기준)
              </p>
            </div>

            <div className="info-box">
              <div className="info-title">연구 건수</div>
              <h2>
                12만 <span>건 이상</span>
              </h2>
              <p>
                골든밸런스 다이어트 프로그램
                <br />
                R&D 참여자 수
              </p>
            </div>

            <div className="info-box">
              <div className="info-title">요요 관리율</div>
              <h2>
                100<span>%</span>
              </h2>
              <p>
                골든밸런스 다이어트 프로그램
                <br />
                참여자 감량 후
                <br />
                팔로업 프로그램 이행률
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section2">
        <div className="contents-container inner">
          <div className="contents-group">
            <div className="contents-box">
              <h2>
                <span>내 몸에 맞춘</span>
                <br />
                1:1 골든밸런스 다이어트
              </h2>
              <p>
                누워만 있어도 뱃살은 폭파, 팔뚝은 반쪽.
                <br /> 눈에 보이는 변화!
              </p>
            </div>

            <div className="contents-box">
              <h2>
                <span>끝까지 가는</span>
                <br />
                1:1 밀착케어 다이어트
              </h2>
              <p>
                100년 전통{' '}
                <span>
                  한의학 박사 연구팀이
                  <br /> 의사, 영양사, 운동처방사와
                </span>{' '}
                함께 구성한 나만의 다이어트 전략
              </p>
            </div>
          </div>
          <div className="contents-detail">
            <h2>
              요요 관리율 100%
              <br />
              다이어트를 경험해보세요.
            </h2>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section3">
        <div className="contents-container">
          <img
            className="contents-main-img"
            src={images.dietDetailImg}
            alt="다이어트 이미지"
          />
        </div>
      </section>
      <section className="contents-section4">
        <div className="contents-container inner">
          <div className="contents-box">
            <h2>
              골든밸런스 고객님들의
              <br />
              <span>비포애프터</span>
            </h2>
            <div className="contents-group">
              <div className="contents-item">
                <div className="contents-item-title">
                  <p>최OO 고객 (26세)</p>
                </div>
                <img
                  className="contents-item-img"
                  src={images.dietmemberImg1}
                  alt="다이어트 이미지"
                />
              </div>
              <div className="contents-item">
                <div className="contents-item-title">
                  <div className="contents-item-type">출산 후 다이어트</div>
                  <p>강OO 고객 (35세)</p>
                </div>
                <img
                  className="contents-item-img"
                  src={images.dietmemberImg2}
                  alt="다이어트 이미지"
                />
              </div>
              <div className="contents-item">
                <div className="contents-item-title">
                  <p>송OO 고객 (43세)</p>
                </div>
                <img
                  className="contents-item-img"
                  src={images.dietmemberImg3}
                  alt="다이어트 이미지"
                />
              </div>
              <div className="contents-item">
                <div className="contents-item-title">
                  <img
                    className="contents-item-icon"
                    src={images.icCountry}
                    alt="국가"
                  />
                  <p>아OO 고객 (32세)</p>
                </div>
                <img
                  className="contents-item-img"
                  src={images.dietmemberImg4}
                  alt="다이어트 이미지"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section5">
        <div className="contents-container inner">
          <div className="contents-box">
            <div className="top-box">
              <div className="number">1</div>
              <h2>퍼스널 골든컬러 다이어트</h2>
            </div>
            <div className="title">
              <h2>나를 위한 다이어트 플랜</h2>
              <h2>퍼스널 골든컬러 분석</h2>
            </div>
            <div className="title-text">
              <div className="title-detail-text">
                <p>개인화된 최적의 다이어트 플랜을 위해</p>
                <p>전문가의 체계적인 체질 분석으로</p>
              </div>
              <p>
                나에게 알맞는 <span className="b-text">퍼스널 골든컬러</span>를
                설정합니다.
              </p>
            </div>
            <div className="img-group">
              <div className="img-item">
                <img
                  className="gold-img"
                  src={images.goldImg1}
                  alt="yellow gold"
                />
                <img
                  className="m-gold-img"
                  src={images.goldMImg1}
                  alt="yellow gold"
                />
              </div>
              <div className="img-item">
                <img
                  className="gold-img"
                  src={images.goldImg2}
                  alt="rose gold"
                />
                <img
                  className="m-gold-img"
                  src={images.goldMImg2}
                  alt="rose gold"
                />
              </div>
              <div className="img-item">
                <img
                  className="gold-img"
                  src={images.goldImg3}
                  alt="white gold"
                />
                <img
                  className="m-gold-img"
                  src={images.goldMImg3}
                  alt="white gold"
                />
              </div>
            </div>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
          <div className="contents-box">
            <div className="sub-title">
              <h2>골든밸런스</h2>
              <h2>골든 컬러 다이어트 프로그램</h2>
            </div>
            <div className="sub-list">
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet1} alt="gold diet icon 1" />
                </div>
                <div className="number">1</div>
                <p className="sub-text">고주파레이저</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet2} alt="gold diet icon 2" />
                </div>
                <div className="number">2</div>
                <p className="sub-text">온열음압 시술</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet3} alt="gold diet icon 3" />
                </div>
                <div className="number">3</div>
                <p className="sub-text">레이저온열 치료</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet4} alt="gold diet icon 4" />
                </div>
                <div className="sub-detail-box">
                  <div className="number">4</div>
                  <p className="sub-text">
                    탄력 & 셀룰라이트분해
                    <br />
                    초음파
                  </p>
                </div>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet5} alt="gold diet icon 5" />
                </div>
                <div className="number">5</div>
                <p className="sub-text">초저온지방파괴 시술</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet6} alt="gold diet icon 6" />
                </div>
                <div className="number">6</div>
                <p className="sub-text">S라인 바디리프팅</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet7} alt="gold diet icon 7" />
                </div>
                <div className="number">7</div>
                <p className="sub-text">지방분해 중저주파 치료</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet8} alt="gold diet icon 8" />
                </div>
                <div className="number">8</div>
                <p className="sub-text">LED피부재생레이저</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet9} alt="gold diet icon 9" />
                </div>
                <div className="number">9</div>
                <p className="sub-text">물방울리프팅</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet10} alt="gold diet icon 10" />
                </div>
                <div className="number">10</div>
                <p className="sub-text">슬림지방분해 약침</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet11} alt="gold diet icon 11" />
                </div>
                <div className="number">11</div>
                <p className="sub-text">지방분해침/원적외선</p>
              </div>
              <div className="sub-item">
                <div className="sub-icon">
                  <img src={images.icGoldDiet12} alt="gold diet icon 12" />
                </div>
                <div className="sub-detail-box">
                  <div className="number">12</div>
                  <div className="sub-text">
                    <p>퍼스널 골든컬러</p>
                    <p>다이어트 환/탕</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="sub-info-text">
              진단되는 개인 퍼스널 골든컬러에 따라 진행 프로그램 구성이 달라질
              수 있습니다.
            </p>
            <div className="sub-info-box">
              <p>다이어트 프로그램은 개인 맞춤 고민 부위별</p>
              <p> 특수 관리가 가능합니다.</p>
            </div>
          </div>
          <div className="contents-box">
            <div className="sub-title">
              <h2>분석부터 관리까지</h2>
              <h2>전 과정 1:1 밀착관리 시스템</h2>
            </div>
            <div className="sub-group">
              <div className="sub-group-list">
                <div className="sub-group-box">
                  <h2>1 Analysis</h2>
                  <p className="sub-group-text">골든밸런스 정밀 분석 검사</p>
                  <div className="sub-list-box">
                    <div className="sub-item">
                      <p className="sub-number">1</p>
                      <p className="sub-text">SMI & BMI 및 체지방률 분석</p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">2</p>
                      <p className="sub-text">
                        복부지방률 분석 / 내장지방단면적 분석
                      </p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">3</p>
                      <p className="sub-text">vital sign 검사</p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">4</p>
                      <p className="sub-text">체질검사 / 신경계 발란스 검사</p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">5</p>
                      <p className="sub-text">혈액순환 검사</p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">6</p>
                      <p className="sub-text">활성산소 및 독소검사</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-group-list">
                <div className="sub-group-box">
                  <h2>2 Plan</h2>
                  <p className="sub-group-text">
                    골든밸런스 퍼스널 골든컬러 다이어트 진단
                  </p>
                  <div className="sub-list-box">
                    <div className="sub-item">
                      <p className="sub-number">1</p>
                      <p className="sub-text">
                        체질에 맞는 골든밸런스 다이어트 컬러 분석
                      </p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">2</p>
                      <p className="sub-text">
                        개인 고민 부위별 특수 맞춤 관리 전략 수립
                      </p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">3</p>
                      <p className="sub-text">
                        요요 없는 성공을 위한 루틴 세팅 및 처방
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-group-list">
                <div className="sub-group-box">
                  <h2>3 Care</h2>
                  <p className="sub-group-text">
                    골든밸런스 밀착관리 팔로업 케어
                  </p>
                  <div className="sub-list-box">
                    <div className="sub-item">
                      <p className="sub-number">1</p>
                      <p className="sub-text">
                        요요 없는 다이어트를 위한 팔로업 케어
                      </p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">2</p>
                      <p className="sub-text">
                        다이어트 궁금증 대표원장 상시 피드백
                      </p>
                    </div>
                    <div className="sub-item">
                      <p className="sub-number">3</p>
                      <p className="sub-text">
                        효과적인 결과를 위한 맞춤식단구성 제공
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section6">
        <div className="contents-container inner">
          <div className="contents-box contents-1">
            <div className="contents-top">
              <p className="type-text">Special Day</p>
              <div className="top-box">
                <div className="number">2</div>
                <h2>웨딩 다이어트</h2>
              </div>
              <div className="top-text">
                <p>
                  웨딩, 바디프로필, 면접, 소개팅 등 내 인생에서 가장 돋보이고
                  싶은 날을 함께합니다.
                </p>
                <p>
                  체중감량과 함께 고운 피부, 건강한 몸매를 단기간에 완성하는
                  다이어트 프로그램입니다.
                </p>
              </div>
            </div>
            <div className="contents-img-box">
              <div className="contents-img">
                <img src={images.bigDietImg1} alt="specail day" />
              </div>
              <div className="sub-group">
                <div className="sub-list-box">
                  <div className="sub-item">
                    <p className="sub-number">1</p>
                    <p className="sub-text">
                      S라인 바디리프팅/슬린라인 팔다리리프팅
                    </p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">2</p>
                    <p className="sub-text">고주파레이저</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">3</p>
                    <p className="sub-text">온열음압 시술</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">4</p>
                    <p className="sub-text">중저주파치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">5</p>
                    <p className="sub-text">레이저온열 치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">6</p>
                    <p className="sub-text">탄력&셀룰라이트분해 초음파</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">7</p>
                    <p className="sub-text">초저온지방파괴 시술</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">8</p>
                    <p className="sub-text">s라인 바디리프팅</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">9</p>
                    <p className="sub-text">림프마사지요법</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">10</p>
                    <p className="sub-text">LED피부재생레이저</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">11</p>
                    <p className="sub-text">물방울리프팅</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">12</p>
                    <p className="sub-text">슬림지방분해 약침</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">13</p>
                    <p className="sub-text">지방분해침/원적외선</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">14</p>
                    <p className="sub-text">퍼스널 골든컬러 다이어트 환/탕</p>
                  </div>
                </div>
                <div className="vertical-line" />
                <div className="detail-sub-text">
                  <p className="sub-text sub-text-normal">
                    본 프로그램은 개인 맞춤 고민 부위별 특수 관리가 가능합니다.
                  </p>
                  <p className="sub-text sub-text-normal">
                    개인별 시술 내용은 효과적인 다이어트를 위해 변경 및 추가될
                    수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-box contents-2">
            <div className="contents-top">
              <p className="type-text">Healing Time</p>
              <div className="top-box top-sub-box">
                <div className="number">3</div>
                <div className="top-sub-title">
                  <h2>갱년기 & 토탈케어</h2>
                  <h2>다이어트</h2>
                </div>
              </div>
              <div className="top-text">
                <p>아름답고 새로운 시작을 함께합니다.</p>
                <p>
                  신체 밸런스와 피부탄력을 살리는 갱년기 여성 집중케어 다이어트
                  프로그램입니다.
                </p>
              </div>
            </div>
            <div className="contents-img-box">
              <div className="contents-img">
                <img src={images.bigDietImg2} alt="Healing Time" />
              </div>
              <div className="sub-group">
                <div className="sub-list-box">
                  <div className="sub-item">
                    <p className="sub-number">1</p>
                    <p className="sub-text">고주파레이저</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">2</p>
                    <p className="sub-text">온열음압 시술</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">3</p>
                    <p className="sub-text">레이저온열 치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">4</p>
                    <p className="sub-text">중저주파치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">5</p>
                    <p className="sub-text">탄력&셀룰라이트분해 초음파</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">6</p>
                    <p className="sub-text">초저온지방파괴 시술</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">7</p>
                    <p className="sub-text">안티에이징 약침</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">8</p>
                    <p className="sub-text">림프마사지요법</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">9</p>
                    <p className="sub-text">LED 피부재생레이저</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">10</p>
                    <p className="sub-text">물방울리프팅</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">11</p>
                    <p className="sub-text">지방분해 약침 /호르몬발란스약침</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">12</p>
                    <p className="sub-text">순환침/ 원적외선</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">13</p>
                    <p className="sub-text">퍼스널 골든컬러 다이어트 환/탕</p>
                  </div>
                </div>
                <div className="vertical-line" />
                <div className="detail-sub-text">
                  <p className="sub-text sub-text-normal">
                    본 프로그램은 개인 맞춤 고민 부위별 특수 관리가 가능합니다.
                  </p>
                  <p className="sub-text sub-text-normal">
                    개인별 시술 내용은 효과적인 다이어트를 위해 변경 및 추가될
                    수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-box contents-3">
            <div className="contents-top">
              <p className="type-text">Pure Moment</p>
              <div className="top-box top-sub-box">
                <div className="number">4</div>
                <div className="top-sub-title">
                  <h2>산후붓기 & 성형 후 붓기</h2>
                  <h2>& 부종 다이어트</h2>
                </div>
              </div>
              <div className="top-text">
                <p>붓기가 살이 되지 않을까 하는 걱정을 함께 덜어드립니다.</p>
                <p>
                  혈액순환 개선, 독소제거에 집중한 표적 스페셜 케어 다이어트
                  프로그램입니다.
                </p>
              </div>
            </div>
            <div className="contents-img-box">
              <div className="contents-img">
                <img src={images.bigDietImg3} alt="Pure Moment" />
              </div>
              <div className="sub-group">
                <div className="sub-list-box">
                  <div className="sub-item">
                    <p className="sub-number">1</p>
                    <p className="sub-text">고주파레이저</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">2</p>
                    <p className="sub-text">온열음압 시술</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">3</p>
                    <p className="sub-text">레이저온열 치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">4</p>
                    <p className="sub-text">림프순환자극 치료</p>
                  </div>
                  <div className="sub-item">
                    <p className="sub-number">5</p>
                    <p className="sub-text">퍼스널 골든컬러 다이어트 환/탕</p>
                  </div>
                </div>
                <div className="vertical-line" />
                <div className="detail-sub-text">
                  <p className="sub-text special-text">
                    만성붓기, 하지부종, 산후붓기 및 성형붓기
                    <br /> 관리에 탁월한 프로그램입니다.
                  </p>
                  <p className="sub-text sub-text-normal">
                    본 프로그램은 개인 맞춤 고민 부위별 특수 관리가 가능합니다.
                  </p>
                  <p className="sub-text sub-text-normal">
                    개인별 시술 내용은 효과적인 다이어트를 위해 변경 및 추가될
                    수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contents-section7">
        <div className="contents-container inner">
          <div className="contents-box">
            <div className="contents-title">
              <div className="contents-icon">
                <img src={images.icDietMemo} alt="memo" />
              </div>
              <div className="contents-title-text">
                <h2> 골든밸런스 다이어트 센터</h2>
                <h2> 프로그램 후기</h2>
              </div>
            </div>
            <Button
              className="btn-outline btn-outline-primary"
              onClick={handleReviewClick}
            >
              다이어트 치료 후기 보기
            </Button>
          </div>
        </div>
      </section>
      <section className="contents-section8">
        <div className="contents-container">
          <div className="contents-box inner">
            <div className="contents-title-box">
              <div className="contents-title-text">
                <h2>완벽한 바디핏으로</h2>
                <h2>다시 태어나요!</h2>
              </div>
              <h2>
                정답은 <span>골든밸런스</span>
              </h2>
            </div>
            <div className="btn-box">
              <Button className="btn" onClick={handleReservationsClick}>
                상담 예약 신청하기 →
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* 최상단 이동 */}
      <TopButton className="top-btn" />

      <Footer />
    </main>
  );
});
