/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Utils
import Utils from '../../utils/Utils';

const ConfirmModal = () => {
  const { modalTitle, modalBody, confirmEvent } = useSelector(
    state => state.modal,
  );

  return (
    <Container className="confirm-modal">
      <div className="confirm-title">
        {/* 타이틀 */}
        <h2>{modalTitle}</h2>
        {/* 본문 */}
        <p>{modalBody}</p>
      </div>
      <div className="confirm-box">
        {/* 아니요 */}
        <div onClick={Utils.closeModal} className="confirm-btn">
          아니요
        </div>
        {/* 예 */}
        <div onClick={confirmEvent} className="confirm-btn blue">
          예
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section``;

export default ConfirmModal;
