import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Constants
import { RouterPath } from '@common';
import REVIEW_TYPE from '../../common/constants/ReviewType';

// Components
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiGetReviewList } from '../../api/RestAPI';

// Image
import { images } from '../../assets';
import { CustomPagination } from '../../components';

// Util
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 치료후기 > 리스트 ]
// ***********************************************************
export default React.memo(function Review({
  isAuthorized = localStorage.getItem('auth'),
}) {
  const navigate = useNavigate();

  const { loadingShow } = useSelector(state => state.loading);

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  const [searchParams, setSearchParams] = useSearchParams({
    type: REVIEW_TYPE.DIET.value,
    page: 1,
  });
  const page = searchParams.get('page'); // 현재 페이지
  const type = searchParams.get('type'); // 현재 페이지

  const [reviewList, setReviewList] = useState([]); // 리뷰 리스트
  const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 검색 유형 선택
  const searchTypeHandler = searchType => {
    setSearchParams({ type: searchType, page: 1 });
  };

  // 페이지 이동
  const movePage = num => {
    setSearchParams({ type, page: num });
  };

  // 상세 페이지 이동
  const moveToDetail = idx => {
    navigate(`${RouterPath.ReviewDetail}/${idx}`, { state: { page } });
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 리뷰 리스트
  const getReviewList = async () => {
    try {
      const { data } = await apiGetReviewList({
        type,
        page,
        size: 12,
      });

      if (data) {
        setTotalPages(data.data.totalPages);
        setReviewList([...data.data.list]);
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    if (isAuthorized) getReviewList();

    return () => {
      setReviewList([]);
    };
  }, [searchParams]);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="review">
      {/* 헤더 */}
      <Header type="basic" />

      {/* 페이지 썸네일 */}
      <section className="main-section">
        <div className="main-img">
          <img src={images.reviewImg} alt="메인 이미지" />
        </div>
      </section>

      {/* 치료후기 리스트 */}
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-box">
            {/* 타이틀 */}
            <h2>치료후기</h2>
            <p className="text">
              골든힐 한의원을 통해 <br className="br-500" />
              오늘 더 건강한 삶을 찾은 분들의 이야기입니다
            </p>

            {/* 검색 유형 */}
            <ul className="contents-tab">
              {Object.values(REVIEW_TYPE).map((t, index) => (
                <li
                  key={`review-type-${index}`}
                  onClick={() => searchTypeHandler(t.value)}
                  style={{
                    color: type === t.value ? '#FFF' : '#252525',
                    backgroundColor: type === t.value ? '#DB9E73' : '#D9D9D9',
                  }}
                >
                  {t.label}
                </li>
              ))}
            </ul>

            {/* 리스트 */}
            {!loadingShow && (
              <>
                <ul className="contents-list">
                  {reviewList.length > 0 ? (
                    reviewList.map((review, index) => (
                      <li
                        className="contents-item"
                        key={`review-list-${index}`}
                        onClick={() => moveToDetail(review.reviewIdx)}
                      >
                        <div className="contents-img">
                          <img
                            src={
                              review?.thumbnailPath
                                ? `${process.env.REACT_APP_STORAGE_URL}/${review.thumbnailPath}`
                                : images.growthImg5
                            }
                            alt=""
                          />
                        </div>
                        <div className="tab">{review.categoryName}</div>
                        <p className="title">{review.reviewTitle}</p>
                        {/* 등록시간 - 2024.02.06 요청건 */}
                        <p className="day">
                          {`${review.regDate}`.split(' ')[0]}
                        </p>
                      </li>
                    ))
                  ) : (
                    // TODO ::: 조회 결과가 없을 때 - CSS 수정 필요
                    <div className="blank-box">
                      <div className="icon">
                        <img src={images.icWarning} alt="느낌표" />
                      </div>
                      <p>조회 리뷰가 없습니다.</p>
                    </div>
                  )}
                </ul>

                {/* 페이징 */}
                {totalPages > 0 && (
                  <CustomPagination
                    pageCount={totalPages}
                    pageNum={page}
                    setPage={movePage}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
