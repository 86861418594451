import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { images } from '../../assets';

export default React.memo(function Introduction(props) {
  const navigate = useNavigate();

  const handleReservationsClick = () => {
    navigate('/reservations');
  };

  const handleProstateClick = () => {
    navigate('/prostate');
  };

  const handleGrowthClick = () => {
    navigate('/growth');
  };

  return (
    <main id="introduction">
      <Header type="basic" />
      {/* 페이지 썸네일 */}
      <section className="main-section">
        <div className="main-img">
          <img src={images.introductionImg1} alt="메인 이미지" />
        </div>
      </section>
      <div className="contents-box inner">
        <section className="contents-section top-section">
          <div className="contents-container flex-box p-0">
            <div className="title">
              <h2>오늘 더 건강한 삶</h2>
            </div>
            <div className="contents-text text-box">
              <p>
                “오늘은 어디가 불편하셔서 오셨나요?”
                <br />
                골든힐 한의원이 지난 100년 동안 많은 분의
                <br />
                건강한 삶을 만들며 건넨 질문입니다.
              </p>
              <p>
                오랜 세월, 다양한 환자를 만나며 자부할 수 있는
                <br />
                골든힐만의 한의학 비방을 쌓아왔습니다.
              </p>
              <p>
                오늘도 골든힐 한의원은 환자분이
                <br />
                어제보다 더 건강한 하루를 살기 바라는
                <br />
                마음으로 한 분 한 분을 진료하고 있습니다.
              </p>
              <p>
                ‘오늘 더 건강한 내 삶’을{' '}
                <span style={{ color: '#DBA075' }}>골든힐 한의원</span>에서
                시작하세요.
              </p>
            </div>
          </div>
        </section>
        <section className="contents-section info-section">
          <div className="contents-container flex-box p-0">
            <div className="title">
              <h2>
                SINCE 1925
                <br />
                4대 한의 명가 골든힐 한의원
              </h2>
            </div>
            <div className="contents-text text-box">
              <p>
                1925년 시작된 한의학의 길을 가업으로 삼아 4대를 이어온
                <br className="br-pc" />
                골든힐 한의원은 <br className="br-500" />
                100년 전통 한방 노하우를 계승하는 동시에,
                <br />
                현대인의 환경에 알맞는 한방치료 연구를
                <br className="br-500" /> 이어가고 있습니다.
              </p>
            </div>
          </div>
          <div className="contents-list">
            <div className="contents-item">
              <div className="contents-img-box">
                <img src={images.ceoImg1} alt="ceo" />
              </div>
              <p className="info-title">4대 원장</p>
              <p className="info-sub-title">한혁규 박사</p>
              <p className="info-text">
                한류 스타, 스포츠 스타,
                <br />
                유명 기업인 주치의
              </p>
            </div>

            <div className="contents-item">
              <div className="contents-img-box">
                <img src={images.ceoImg2} alt="ceo" />
              </div>
              <p className="info-title">3대 원장</p>
              <p className="info-sub-title">한승섭 박사</p>
              <p className="info-text">정치인, 기업 총수 주치의</p>
            </div>

            <div className="contents-item">
              <div className="contents-img-box">
                <img src={images.ceoImg3} alt="ceo" />
              </div>
              <p className="info-title">2대 원장</p>
              <p className="info-sub-title">한정식 원장</p>
              <p className="info-text">박정희 대통령 주치의</p>
            </div>

            <div className="contents-item">
              <div className="contents-img-box">
                <img src={images.ceoImg4} alt="ceo" />
              </div>
              <p className="info-title">1대 원장</p>
              <p className="info-sub-title">이귀례 원장</p>
              <p className="info-text">이승만 대통령 주치의</p>
            </div>
          </div>
        </section>
        <section className="contents-section p-0">
          <div className="contents-img">
            <img src={images.introductionImg2} alt="서브 이미지" />
          </div>
          <div className="contents-container flex-box contents-2">
            <div className="title">
              <h2>
                건강한 100세 시대를 만드는
                <br /> 골든힐 100년 역사
              </h2>
            </div>
            <div className="contents-text m-0">
              <p className="text text1">
                골든힐 한의원은 100년 간 쌓아온 역사를 자부합니다.
                <br />
                온고이지신(溫故而知新).
                <br />한 세기 동안 진료를 이어가며 지킨
                <br className="br-500" /> 골든힐의 철학입니다.
                <br />
                전통의 가치를 지키면서도 현대 의학의 기술과 지식을 활용하여
                환자에게 맞는,
                <br className="br-500" /> 더 정확한 진단과 치료를 제공하고자
                노력합니다.
              </p>
              <p className="text text2">
                전립선 연구센터를 설립하여 많은 남성이 겪는 ’전립선 질환’을
                연구하며 치료하고 있습니다. 키성장 연구센터에서는 오랜 시간 동안
                쌓은 진료 데이터와 새로운 의학 지식을 접목한 진료를 제공하며
                부모님과 아이들의 키성장 고민을 해결하고 있습니다.
              </p>
              <p className="text m-0">
                골든힐은 4대를 이어온 100년 한의 명가만의 노하우를 바탕으로
                <br />
                100세 시대인 현대 라이프스타일에 맞는 <br className="br-500" />
                변화를 추구하여
                <br className="br-pc" />
                보다 더 많은 이들에게
                <br className="br-500" />
                따듯하게 다가가 건강한 삶을 전하는
                <br />
                새로운 한의학의 길을 만들어 가겠습니다.
              </p>
            </div>
          </div>
        </section>
        <section className="contents-section reservation-section p-0">
          <div className="contents-img">
            <img src={images.introductionImg3} alt="서브 이미지" />
          </div>
          <div className="contents-container">
            <h2>예약제 진료, 1:1 맞춤 처방</h2>
            <div className="contents-text">
              <p className="text m-0">
                골든힐 한의원에서는 환자분에게
                <br className="br-500" /> 꼭 맞는 심도 있는 진료와{' '}
                <br className="br-500" />
                개인별 1:1 맞춤 처방을 위해
                <br />
                모든 진료를 예약제로 운영하고 있습니다.
              </p>
            </div>
            <div className="btn-box">
              <div className="title-btn">
                <Button
                  className="btn btn-primary"
                  onClick={handleReservationsClick}
                >
                  상담 예약 신청하기 →
                </Button>
              </div>
              <div className="btn-outline">
                <Button
                  className="btn-outline-primary"
                  onClick={handleProstateClick}
                >
                  전립선 치료 소개 보기
                </Button>
              </div>
              <div className="btn-outline">
                <Button
                  className="btn-outline-primary"
                  onClick={handleGrowthClick}
                >
                  키성장 치료 소개 보기
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
