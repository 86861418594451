import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { images } from '../../assets';

export default React.memo(function Doctors(props) {
  return (
    <main id="doctors">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img pc-main-img">
          <img src={images.doctorImg1} alt="의료진 사진" />
          <div className="main-text-box">
            <div className="main-text main-pd-right">3대 한승섭 박사</div>
            <div className="main-text main-pd-left">4대 한혁규 박사</div>
          </div>
        </div>

        <div className="main-img m-main-img">
          <img src={images.mDoctorImg1} alt="의료진 사진" />
        </div>
      </section>
      <div className="contents-box inner">
        <section className="contents-section">
          <div className="contents-container">
            <div className="contents-list">
              <h2 className="contents-main-title">“3대를 넘어 4대로”</h2>
              <div className="contents-detail">
                <div className="contents-title">
                  <h4>
                    <span>대표원장</span> 한혁규 박사
                  </h4>
                  <div className="contents-item text p-0 m-0">
                    <p className="title">학력</p>
                    <p>경희대 한의학 박사</p>
                  </div>
                  <div className="contents-item">
                    <p className="title">약력</p>
                    <p>대한플라즈마학회 이사</p>
                    <p>금산미학여성의학 연구소장</p>
                    <p>대한암한의학회 정회원</p>
                    <p>대한약침학회 정회원</p>
                    <p>한방부인과학회 정회원</p>
                    <p>前 금산미학한의원 원장</p>
                    <p>前 닥터한스한의원 대표 원장</p>
                    <p>골든타임 키성장 빅데이터 연구센터 운영</p>
                    <p>골든에이지 전립선 솔루션 연구센터 운영</p>
                  </div>
                </div>
                <div className="contents-text">
                  <p className="text">
                    안녕하세요.
                    <br /> 골든힐 한의원 한혁규 대표원장입니다.
                    <br /> 골든힐 한의원의 전신인 금산한의원의 선대 곁에서 어릴
                    적부터 한의학과 친해졌습니다.
                    <br /> 많은 분의 <span>삶</span>이 <span>회복</span>되고{' '}
                    <span>변화</span>되는 과정을 목격했습니다.
                    <br /> 저도 더 많은 분들에게 건강한 삶을 드리고 싶은 마음에
                    자연스레 한의학의 길을 택했습니다.
                  </p>
                  <p className="text">
                    <span>100년</span>간 이어온 한의학 자산이 전통에만 머물지
                    않고 현대 라이프스타일에 맞춰
                    <br /> 실제로 잘 적용될 수 있도록{' '}
                    <span>동서양의 새로운</span> 의학 지식 연구를 이어가고
                    있습니다.
                    <br /> 더 많은 분들에게 친근하게 다가가 현대인에게 필요한
                    한의학 지식을 전하고자
                    <br /> TV 출연과 유튜브, 블로그, SNS 활동을 꾸준히 이어가고
                    있습니다.
                  </p>
                  <p className="text m-0">
                    한 분 한 분이 <span>오늘 더 건강한 삶</span>을 누릴 수
                    있도록 오늘도 노력하는 한의사가 되겠습니다.
                  </p>
                </div>
              </div>
              <div className="contents-detail m-contents-detail">
                <div className="contents-title">
                  <div className="contents-item p-0 m-0">
                    <p className="title">저서</p>
                    <p>전립선 치료 10일의 기적 (2021)</p>
                    <p>우리가 몰랐던 백년 건강 동의보감 (2021)</p>
                  </div>
                  <div className="contents-item">
                    <p className="title">방송</p>
                    <p>YTN 뉴스 {'<전립선 질환의 한의학적 치료는?>'}</p>
                    <p>MBC 마이 리틀 텔리비전V2</p>
                    <p>EBS 아주 각별한 기행 {'<한혁규의 장수 만세>'}</p>
                    <p>JTBC 최고의 처방 미라클 푸드</p>
                    <p>MBN 알토란 / 즐거운 주말, 건강 신호등</p>
                    <p>외 다수 출현</p>
                  </div>
                </div>
                <div className="contents-text m-contents-text">
                  <p className="text">
                    안녕하세요.
                    <br /> 골든힐 한의원 한혁규 대표원장입니다.
                    <br /> 골든힐 한의원의 전신인 금산한의원의
                    <br className="br-500" /> 선대 곁에서 어릴 적부터 한의학과
                    <br className="br-500" />
                    친해졌습니다. 많은 분의 <span>삶</span>이 <span>회복</span>
                    되고
                    <br className="br-500" /> <span>변화</span>되는 과정을
                    목격했습니다.
                    <br className="br-500" /> 저도 더 많은 분들에게 건강한 삶을
                    <br className="br-500" />
                    드리고 싶은 마음에 자연스레 한의학의
                    <br className="br-500" /> 길을 택했습니다.
                  </p>
                  <p className="text">
                    <span>100년</span>간 이어온 한의학 자산이 전통에만
                    <br className="br-500" /> 머물지 않고 현대 라이프스타일에
                    맞춰
                    <br className="br-500" /> 실제로 잘 적용될 수 있도록{' '}
                    <span>동서양의 새로운</span> 의학 지식 연구를 이어가고
                    있습니다.
                    <br className="br-500" /> 더 많은 분들에게 친근하게 다가가
                    <br className="br-500" />
                    현대인에게 필요한 한의학 지식을 전하고자 TV 출연과 유튜브,
                    블로그, SNS 활동을
                    <br className="br-500" /> 꾸준히 이어가고 있습니다.
                  </p>
                  <p className="text m-0">
                    한 분 한 분이 <span>오늘 더 건강한 삶</span>을
                    <br className="br-500" /> 누릴 수 있도록 오늘도 노력하는
                    <br className="br-500" />
                    한의사가 되겠습니다.
                  </p>
                </div>
                <div className="contents-detail-img">
                  <img src={images.doctorImg3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="special-section">
        <div className="contents-container pc-container">
          <div className="contents-text-group">
            <div className="contents-title-box">
              <p>명예원장</p>
              <h2>한승섭 박사</h2>
            </div>
            <div className="contents-text-box">
              <div>
                <p className="contents-text-detail">학력</p>
                <p>경희대 한의학 박사</p>
              </div>
              <div>
                <p className="contents-text-detail">약력</p>
                <p>
                  前 경희대 사회교육원 교수
                  <br /> 대한한의사협회 부회장
                  <br /> 前 경희대 한의학 과학기술 전문위원
                  <br /> 금산미학 한의원 대표원장
                  <br />
                  대한한방피부미용학회 특별회원
                  <br /> 前 대한축구협회 월드컵조직위원회 의무위원
                  <br /> 2002 한일월드컵 FIFA 국가대표선수 특별주치의
                  <br />
                  가정의학필독서 &quot;가정동의보감&quot; 외 다수 저자
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-container m-container">
          <div>
            <img src={images.doctorMbImg4} alt="모바일 사진" />
          </div>
          <div className="contents-text-group">
            <div className="contents-title-box">
              <p>명예원장</p>
              <h2>한승섭 박사</h2>
            </div>
            <div className="contents-text-box">
              <div>
                <p className="contents-text-detail">학력</p>
                <p>경희대 한의학 박사</p>
              </div>
              <div>
                <p className="contents-text-detail">약력</p>
                <p>
                  前 경희대 사회교육원 교수
                  <br /> 대한한의사협회 부회장
                  <br /> 前 경희대 한의학 과학기술 전문위원
                  <br /> 금산미학 한의원 대표원장
                  <br />
                  대한한방피부미용학회 특별회원
                  <br /> 前 대한축구협회 월드컵조직위원회 의무위원
                  <br /> 2002 한일월드컵 FIFA 국가대표선수 특별주치의
                  <br />
                  가정의학필독서 &quot;가정동의보감&quot; 외 다수 저자
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contents-box inner">
        <section className="contents-section">
          <div className="contents-container contents-dt-container">
            <div className="contents-list-box">
              <div className="contents-item-box">
                <div className="contents-item-img">
                  <img src={images.doctorImg5} alt="의료진 사진 5" />
                </div>

                <div className="contents-list contents-info m-0 contents-dt-list">
                  <h4 className="name">강세일 원장</h4>
                  <div className="contents-group">
                    <div className="contents-item p-0">
                      <p className="title">학력</p>
                      <p>원광대학교 한의과대학</p>
                    </div>
                    <div className="contents-item p-0">
                      <p className="title">약력</p>
                      <p>골든힐 골든에이지 전립선 연구센터장</p>
                      <p>금산미학 전립선연구소장</p>
                      <p>자연드림유기농치유재단 이사장</p>
                      <p>아이쿱요양병원 대표원장</p>
                      <p>前 우석대학교 김제한방병원</p>
                      <p>前 동의대학교 한방병원</p>
                      <p>前 강세일한의원 원장</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contents-item-box">
                <div className="contents-item-img">
                  <img src={images.doctorImg6} alt="의료진 사진 6" />
                </div>

                <div className="contents-list contents-info m-0 contents-dt-list">
                  <h4 className="name">정계진 박사</h4>
                  <div className="contents-group">
                    <div className="contents-item p-0">
                      <p className="title">학력</p>
                      <p>경희대학교 한의학박사</p>
                    </div>
                    <div className="contents-item p-0">
                      <p className="title">약력</p>
                      <p>경희대학교 한의학과 외래교수</p>
                      <p>한국한의학연구원 책임연구원</p>
                      <p>처방제형학회 정회원</p>
                      <p>한방비만학회 정회원</p>
                      <p>본초학회 정회원</p>
                      <p>연부조직학회 정회원</p>
                      <p>前 금산한의원</p>
                      <p>前 미인한의원</p>
                      <p>前 정계진한의원</p>
                    </div>
                    <div className="contents-item p-0">
                      <p className="title">논문 및 저서</p>
                      <p>보건복지부, 한국한의학연구원 논문 및</p>
                      <p>연구보고 등 다수 해외 및 국내 논문 및 저서</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="contents-box inner">
        <section className="contents-section">
          <div className="contents-container">
            <div className="contents-list2">
              {/* pc일때 */}
              <div className="contents-img">
                <img src={images.doctorImg2} alt="의료진 사진 2" />
              </div>
              <div className="main-contents-list contents-list contents-info m-0">
                <h4 className="name">
                  <span>자문위원</span> 한영규 의사
                </h4>
                <div className="contents-item p-0">
                  <p className="title">약력</p>
                  <p>서울아산병원 연구진</p>
                  <p>근로복지공단 코로나19 파견의료진</p>
                  <p>대한공중보건의사협의회 대의원</p>
                </div>
              </div>

              {/* 모바일일때 */}
              <div className="m-contents-list contents-list contents-info m-0">
                <h4 className="name">
                  <span>자문위원</span> 한영규 의사
                </h4>
                <div className="contents-item p-0">
                  <p className="title">약력</p>
                  <p>서울아산병원 연구진</p>
                  <p>근로복지공단 코로나19 파견의료진</p>
                  <p>대한공중보건의사협의회 대의원</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
