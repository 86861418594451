import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets';
import Utils from '../../utils/Utils';
import { RouterPath } from '../../common';

export default React.memo(function Landing(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGrowth, setIsHoveredGrowth] = useState(false);
  const [isHoveredProstate, setIsHoveredProstate] = useState(false);
  const [isHoveredFocus, setIsHoveredFocus] = useState(false);
  const [isHoveredDiet, setIsHoveredDiet] = useState(false);

  const navigate = useNavigate();

  // Redux Store 조회
  const info = useSelector(state => {
    return state.info;
  });

  // 새 브라우저 탭 열기
  const openNewTab = url => {
    if (!url) Utils.openModal({ text: '준비중입니다.' });
    else window.open(url);
  };

  const navigateToHome = () => {
    navigate(RouterPath.Home); // '/home' 경로로 이동
  };

  const navigateToGrowth = () => {
    navigate(RouterPath.Growth); // '/growth' 경로로 이동
  };

  const navigateToProstate = () => {
    navigate(RouterPath.Prostate); // '/prostate' 경로로 이동
  };

  const navigateToFocus = () => {
    navigate(RouterPath.Focus); // '/focus' 경로로 이동
  };

  const navigateToDiet = () => {
    navigate(RouterPath.Diet); // '/diet' 경로로 이동
  };

  return (
    <main id="landing">
      <section className="landing-section">
        <div className="landing-container inner">
          <header>
            <div className="landing-header">
              <div
                className={`logo-container ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={navigateToHome}
                // onClick={navigateToDiet}
              >
                <img
                  src={images.logoWhiteImg}
                  alt="logo"
                  className="logo logo-white"
                />
                <img
                  src={images.logoGreenImg}
                  alt="logo"
                  className="logo logo-gold"
                />
              </div>
            </div>
          </header>
          <div className="landing-contact">
            <div className="landing-text">
              <h2>
                오늘 더 건강한 내 삶을
                <br />
                <span>골든힐 한의원</span>에서 시작하세요.
              </h2>
            </div>
            <ul className="contact-list">
              <li
                className="contact-item"
                onClick={navigateToGrowth}
                onMouseEnter={() => setIsHoveredGrowth(true)}
                onMouseLeave={() => setIsHoveredGrowth(false)}
              >
                <div className="contact-title">
                  <p className="title">골든타임</p>
                  <p className="text">키성장 빅데이터 연구센터</p>
                </div>
                <div className="arrow">
                  <div className={isHoveredGrowth ? 'img-hovered' : 'img'}>
                    <img
                      src={images.arrowRight}
                      alt="right"
                      className="normal-img"
                    />
                    <img
                      src={images.arrowRightLong1}
                      alt="right"
                      className="hover-img"
                    />
                  </div>
                </div>
              </li>
              <li
                className="contact-item"
                onClick={navigateToProstate}
                onMouseEnter={() => setIsHoveredProstate(true)}
                onMouseLeave={() => setIsHoveredProstate(false)}
              >
                <div className="contact-title">
                  <p className="title">골든에이지</p>
                  <p className="text">전립선 솔루션 연구센터</p>
                </div>
                <div className="arrow">
                  <div className={isHoveredProstate ? 'img-hovered' : 'img'}>
                    <img
                      src={images.arrowRight}
                      alt="right"
                      className="normal-img"
                    />
                    <img
                      src={images.arrowRightLong1}
                      alt="right"
                      className="hover-img"
                    />
                  </div>
                </div>
              </li>
              <li
                className="contact-item"
                onClick={navigateToDiet}
                onMouseEnter={() => setIsHoveredDiet(true)}
                onMouseLeave={() => setIsHoveredDiet(false)}
              >
                <div className="contact-title">
                  <p className="title">골든밸런스</p>
                  <p className="text">다이어트 센터</p>
                </div>
                <div className="arrow">
                  <div className={isHoveredDiet ? 'img-hovered' : 'img'}>
                    <img
                      src={images.arrowRight}
                      alt="right"
                      className="normal-img"
                    />
                    <img
                      src={images.arrowRightLong1}
                      alt="right"
                      className="hover-img"
                    />
                  </div>
                </div>
              </li>

              {/* <li
                className="contact-item"
                onClick={navigateToFocus}
                onMouseEnter={() => setIsHoveredFocus(true)}
                onMouseLeave={() => setIsHoveredFocus(false)}
              >
                <div className="contact-title">
                  <p className="text">집중력</p>
                  <p>골든스터디 클리닉</p>
                </div>
                <div className="arrow">
                  <div className={isHoveredFocus ? 'img-hovered' : 'img'}>
                    <img
                      src={images.arrowRight}
                      alt="right"
                      className="normal-img"
                    />
                    <img
                      src={images.arrowRightLong}
                      alt="right"
                      className="hover-img"
                    />
                  </div>
                </div>
              </li> */}
            </ul>
            <div className="contact-options">
              <div
                className="contact-option"
                onClick={() => openNewTab(info?.kakaoUrl)}
              >
                <div className="contact-icon">
                  <img src={images.landingIcon1} alt="kakao" />
                </div>
                <div className="contact-text">
                  <div style={{ color: '#FFF' }}>카톡 상담</div>
                </div>
              </div>
              <div className="contact-option">
                <div className="contact-icon">
                  <img src={images.landingIcon2} alt="call" />
                </div>
                <div className="contact-text">
                  <a href={`tel:${info?.hp}`} style={{ color: '#FFF' }}>
                    전화 문의
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
});
