import api from '../utils/Api';

// API Prefix
const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'http://localhost:4001';
const API_VERSION = 'api/v1';
const API_PREFIX = `${baseURL}/${API_VERSION}`;

// API Type
const API_AUTH = `${API_PREFIX}/auth`;
const API_USER = `${API_PREFIX}/user`;
const API_HOME = `${API_PREFIX}/home`;
const API_COMMUNITY = `${API_PREFIX}/community`;
const API_RESERVATION = `${API_PREFIX}/reservation`;

// Request > Headers > multipart/form-data
export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// ----------------------------------------------------------
// [ AUTH ] 회원인증
//  - 00* : 로그인, 회원가입 - SNS 연동 ( GOOGLE, KAKAO )
//  - 01* : 토큰
// ----------------------------------------------------------

// GDHC_IF_001	로그인
export const apiLoginGoogleCallback = code => {
  return api.get(`${API_AUTH}/google-callback`, { params: { code } });
};

// GDHC_IF_002	로그인
export const apiLoginKakaoCallback = code => {
  return api.get(`${API_AUTH}/kakao-callback`, { params: { code } });
};

// GDHC_IF_010	Access Token 재발급
export const apiUpdateAccessToken = data => {
  return api.get(`${API_AUTH}/access-token`);
};

// ----------------------------------------------------------
// [ HOME ] 홈
//  - 1** : 메인 페이지 관련 정보 불러오기
// ----------------------------------------------------------

// GDHC_IF_101 - 기초 정보
export const apiGetHomeAbout = () => {
  return api.get(`${API_HOME}/about`);
};

// GDHC_IF_102 - 배너 조회
export const apiGetBannerList = () => {
  return api.get(`${API_HOME}/banners`);
};

// GDHC_IF_103 - 팝업 조회
export const apiGetPopupList = popupType => {
  return api.get(`${API_HOME}/popups/${popupType}`);
};

// ----------------------------------------------------------
// [ USER ] 마이페이지
//  - 2** : 내 정보 관리
// ----------------------------------------------------------

// GDHC_IF_201	마이페이지 > 내 정보 조회
export const apiGetMyinfo = () => {
  return api.get(`${API_USER}/my-info`);
};

// ----------------------------------------------------------
// [ COMMUNITY ] 커뮤니티
//  - 3** : 게시물 조회
// ----------------------------------------------------------

// GDHC_IF_301	커뮤니티 > Q&A 리스트
export const apiGetQnaList = data => {
  return api.get(`${API_COMMUNITY}/qna`, { params: data });
};

// GDHC_IF_302	커뮤니티 > Q&A 상세
export const apiGetQnaDetail = idx => {
  return api.get(`${API_COMMUNITY}/qna/${idx}`);
};

// GDHC_IF_303	커뮤니티 > 공지사항 리스트
export const apiGetNoticeList = data => {
  return api.get(`${API_COMMUNITY}/notice`, { params: data });
};

// GDHC_IF_304	커뮤니티 > 공지사항 상세
export const apiGetNoticeDetail = idx => {
  return api.get(`${API_COMMUNITY}/notice/${idx}`);
};

// GDHC_IF_305	커뮤니티 > 치료후기 리스트
export const apiGetReviewList = data => {
  return api.get(`${API_COMMUNITY}/reviews`, { params: data });
};

// GDHC_IF_306	커뮤니티 > 치료후기 상세
export const apiGetReviewDetail = idx => {
  return api.get(`${API_COMMUNITY}/review/${idx}`);
};

// GDHC_IF_307	커뮤니티 > 약관 리스트
export const apiGetTermList = () => {
  return api.get(`${API_COMMUNITY}/term`);
};

// GDHC_IF_308	커뮤니티 > 약관 상세
export const apiGetTermDetail = type => {
  return api.get(`${API_COMMUNITY}/term/${type}`);
};

// ----------------------------------------------------------
// [ RESERVATION ] 상담
//  - 7** : 상담 예약
// ----------------------------------------------------------

// GDHC_IF_701	상담 예약
export const apiPostReservationSend = data => {
  return api.post(`${API_RESERVATION}/send`, data);
};
