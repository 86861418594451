import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Components
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';

// API
import { apiGetQnaList } from '../../api/RestAPI';

// Image
import { images } from '../../assets';
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 게시판 > QNA ]
// ***********************************************************
export default React.memo(function Faqs(props) {
  const navigate = useNavigate();

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  const [page, setPage] = useState(1);
  const [qnaList, setQnaList] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 더보기
  const nextPage = () => {
    setPage(page + 1);
  };

  // 내용펼치기
  const toggleDetail = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // QNA 리스트
  const getQnaList = async () => {
    try {
      const { data } = await apiGetQnaList({
        page,
        size: 8,
      });

      if (data.code === 200) {
        setIsLast(data.data.isLast);

        let newList = data.data.list;
        newList = newList.map(item => {
          return {
            ...item,
            // showDetail: false,
          };
        });
        setQnaList([...qnaList, ...newList]);
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getQnaList();
  }, [page]);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="faqs">
      {/* 헤더 */}
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.faqsImg} alt="메인 이미지" />
        </div>
      </section>

      {/* QNA */}
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-list">
            <h2 className="m-0">자주 묻는 질문</h2>
            {qnaList.length > 0 &&
              qnaList.map((qna, index) => (
                <div key={`qna-${index}`} className="contents-item">
                  <div className="title" onClick={() => toggleDetail(index)}>
                    {/* 질문 */}
                    <div className="info">{qna?.qnaQuestion || ''}</div>
                    {/* 토클 버튼 */}
                    <div className="icon">
                      <img
                        src={images.arrowDownGray}
                        alt="Toggle"
                        className={`toggle-arrow ${
                          activeIndex === index ? 'rotated' : ''
                        }`}
                      />
                    </div>
                  </div>
                  {/* 답변 */}
                  {activeIndex === index && (
                    <div className="info-detail">{qna?.qnaAnswer || ''}</div>
                  )}
                </div>
              ))}
            {/* 더보기 */}
            {!isLast && qnaList.length > 0 && (
              <div className="btn-outline">
                <Button className="btn-outline-primary" onClick={nextPage}>
                  더보기
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
