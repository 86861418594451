/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Utils
import Utils from '../../utils/Utils';

const PopupModal = () => {
  const { modalTitle, modalBody, data, closeEvent, confirmEvent } = useSelector(
    state => state.modal,
  );
  const {
    popupIdx = '',
    orderNo = '',
    actionLink = '',
    imageName = '',
    imagePath = '',
    mHeight = '',
    mWidth = '',
    pcHeight = '',
    pcWidth = '',
  } = data;

  const [isChecked, setIsChecked] = useState(false);
  // let width;
  // let height;

  // // Mobile
  // if (
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent,
  //   )
  // ) {
  //   width = mWidth;
  //   height = mHeight;
  // }
  // // PC
  // else {
  //   width = pcWidth;
  //   height = pcHeight;
  // }

  const disablePopup = e => {
    const { checked } = e.target;
    setIsChecked(checked);

    if (checked) {
      setIsChecked(false);
      confirmEvent();

      if (!data.hasNext) Utils.closeModal({ closeEvent: undefined });
    }
  };

  return (
    <Container className="popup-modal">
      <div className="popup-container">
        <PopupImage
          // 미사용 결정 - 2024.02.15
          // style={{ width, height }}
          className={`popup-img ${actionLink && 'pointer'}`}
          onClick={() => {
            if (actionLink) {
              window.open(actionLink);
            }
          }}
        >
          <img
            src={`${process.env.REACT_APP_STORAGE_URL}/${imagePath}`}
            alt=""
          />
        </PopupImage>
        <div className="popup-contents d-flex justify-content-between">
          <form className="form">
            {/* 오늘하루 보지않기 */}
            <input
              id="disable-popup"
              type="checkbox"
              checked={isChecked}
              onChange={disablePopup}
            />
            <label htmlFor="disable-popup">오늘 하루 보지않기</label>
          </form>
          {/* 참 닫기 */}
          <div
            className="pointer"
            onClick={() => Utils.closeModal({ closeEvent })}
          >
            창닫기
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section``;

const PopupImage = styled.div`
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

export default PopupModal;
