/* eslint-disable react/function-component-definition */
import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTag = ({ description, path }) => {
  return (
    <Helmet>
      <meta name="description" content={description} data-rh="true" />
      <meta property="og:description" content={description} data-rh="true" />
      <meta
        property="og:url"
        content={`https://www.goldenhill1925.com${path}`}
        data-rh="true"
      />
    </Helmet>
  );
};

export default MetaTag;
