/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { images } from '../../assets';

export default React.memo(function Reservations(props) {
  // 체크박스의 상태를 저장하는 state (초기값은 false)
  const [isChecked, setIsChecked] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleInfo = () => {
    setIsInfoVisible(!isInfoVisible);
  };

  const navigate = useNavigate();

  const handleAgreeClick = () => {
    navigate('/write', { state: { isAgreed: true } });
  };

  return (
    <main id="reservations">
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.reservationsImg} alt="메인 이미지" />
        </div>
      </section>
      <section className="contents-section inner">
        <div className="contents-container">
          <div className="contents-list">
            <div className="contents-title">
              <h2>상담 예약 신청하기</h2>
              <p>상담 예약 신청에 필요한 필수 동의 사항에 동의해주세요.</p>
            </div>
            <div className="contents-item">
              <div className="agree">
                <div className="agree-title">
                  {/* <div className="check">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      style={{ display: 'none' }} // 실제 체크박스 숨기기
                    />
                    <img
                      src={isChecked ? images.check : images.uncheck}
                      onClick={handleCheckboxChange}
                      alt="custom checkbox"
                    />
                  </div> */}
                  <p onClick={handleCheckboxChange}>
                    [필수] 개인정보 수집 및 이용 동의
                  </p>
                </div>
                {/* <div className="arrow" onClick={toggleInfo}>
                  <img
                    src={images.circleDown}
                    alt="arrow"
                    className={isInfoVisible ? 'arrow-rotate' : ''}
                  />
                </div> */}
              </div>
              {/* {isInfoVisible && ( */}
              <div className="contents-info">
                <div className="text-box">
                  <p className="title">1. 목적</p>
                  <p className="text">
                    골든힐 한의원은 고객님들에게 보다 다양한 정보를 제공하고,
                    서비스의 질을 향상시키기 위하여 당사에서 본 활용동의서에
                    동의한 회원의 개인정보를 활용합니다.
                  </p>
                </div>
                <div className="text-box">
                  <p className="title">2. 수집 및 활용 관련 정보</p>
                  <p className="text m-0">
                    개인정보의 이용목적은 아래와 같습니다. 회원님들 중 이
                    개인정보의 마케팅 활용동의서에 동의하신 회원님들의 정보만이
                    제공되며, 제공된 정보는 명시된 이용목적을 벗어나 이용되지
                    않습니다. 개인정보보호관련 법률에 의거 회원님의 정보는
                    안전하게 관리되며, 개인정보의 유출 등 사고가 일어나지 않도록
                    더욱 철저한 보안이 이루어지도록 노력하고 있습니다.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="sub-box">
                    <li>
                      <span>제공대상자:</span> 골든힐 한의원
                    </li>
                    <li>
                      <span>제공정보:</span> 성명, 생년월일, 휴대폰번호
                    </li>
                    <li>
                      <span>이용목적:</span>{' '}
                      <span>
                        상담을 위한 전화 서비스 제공의 마케팅 자료로 활용 제공
                      </span>
                    </li>
                    <li>
                      <span>이용기간:</span> 동의일로부터 3년
                    </li>
                  </ul>
                </div>
                <div className="text-box">
                  <p className="title">3. 제3자 정보제공의 동의 철회</p>
                  <p className="text">
                    마케팅활용동의를 통해 이미 제공된 정보에 대해 동의 철회를
                    요청하시는 경우, 동의상태 변경 또는 철회 요청일로부터 최대
                    7일 이내에, 본인의 정보는 기술적인 방법으로 복구 불가하도록
                    안전하게 철회 및 삭제처리 됩니다.
                  </p>
                </div>
                <div className="text-box">
                  <p className="title">
                    4. 개인정보관련 의견수렴 및 불만처리에 관한 사항
                  </p>
                  <p className="text">
                    골든힐한의원은 원활환 의사소통을 위해 관리 담당자가 있으며
                    연락처는 다음과 같습니다.
                  </p>
                  <ul className="detail">
                    <p>[민원처리센터]</p>
                    <li>
                      <span>전자우편 :</span> goldenhill1925@naver.com
                    </li>
                    <li>
                      <span>전화번호 :</span> 02-545-1925
                    </li>
                    <li>
                      <span>주 소 :</span> 서울특별시 강남구 도산대로 409
                      (청담동 87-4) S&S타워 2층
                    </li>
                  </ul>
                  <p className="text">
                    고객님께서는 동의를 거부하실 수 있으며, 동의 거부 시 각종
                    상담 및 안내 등의 서비스가 제한됩니다.
                  </p>
                </div>
              </div>
              {/* } */}
              <div className="btn-box">
                <Button
                  variant="primary"
                  size="lg"
                  // disabled={!isChecked}
                  style={{
                    backgroundColor: '#DBA075',
                    borderColor: '#DBA075',
                  }}
                  // style={{
                  //   backgroundColor: isChecked ? '#DBA075' : '#D6D6D6',
                  //   borderColor: isChecked ? '#DBA075' : '#D6D6D6',
                  // }}
                  onClick={handleAgreeClick}
                >
                  동의하기
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 최상단 이동 */}
      <TopButton />

      {/* 푸터 */}
      <Footer />
    </main>
  );
});
