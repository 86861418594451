import React, { useEffect, useState } from 'react';
import { images } from '../../assets';

export default React.memo(function CallButton(props) {
  const [showCallBtn, setShowCallBtn] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;
    const footer = document.querySelector('footer');
    const footerTop = footer ? footer.offsetTop : 0;
    const windowHeight = window.innerHeight;
    const callButton = document.querySelector('.call-btn'); // 버튼 요소 선택

    if (scrollY > 100) {
      setShowCallBtn(true);
    } else {
      setShowCallBtn(false);
    }

    // callButton 요소가 존재하는 경우에만 스타일 적용
    if (callButton) {
      const isMobile = window.innerWidth <= 1024; // 모바일 환경 감지
      if (footer && scrollY + windowHeight > footerTop) {
        const bottomPosition =
          scrollY + windowHeight - footerTop + (isMobile ? 40 : 40);
        callButton.style.bottom = `${bottomPosition}px`;
      } else {
        callButton.style.bottom = isMobile ? '40px' : '40px';
      }
    }
  };

  // 모바일 환경을 감지하는 함수
  const isMobile = () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigateToCallPage = () => {
    window.location.href = 'tel:1660-0712'; // 이동할 페이지 URL을 설정
  };

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    showCallBtn && (
      <div
        className={`call-btn ${isHovered ? 'hovered' : ''}`}
        style={{ position: 'fixed' }}
        onMouseEnter={() => !isMobile() && setIsHovered(true)}
        onMouseLeave={() => !isMobile() && setIsHovered(false)}
        onClick={navigateToCallPage}
      >
        <img src={images.icCallButton} alt="call-button" />
        <p>
          무료상담하기
          <br />
          1660-0712
        </p>
      </div>
    )
  );
});
