import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Constants
import { RouterPath } from '@common';

// Components
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TopButton from '../../components/TopButton/TopButton';
import { CustomPagination } from '../../components';

// API
import { apiGetNoticeList } from '../../api/RestAPI';

// Image
import { images } from '../../assets';

// Util
import { handleError } from '../../utils/HandleError';

// ***********************************************************
// [ 게시판 > 공지사항 ]
// ***********************************************************
export default React.memo(function Notices(props) {
  const navigate = useNavigate();

  const { loadingShow } = useSelector(state => state.loading);

  // ***********************************************************
  // [ State ]
  // ***********************************************************

  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const page = searchParams.get('page'); // 현재 페이지

  const [pinnedList, setPinnedList] = useState([]); // 상단 고정 목록
  const [noticeList, setNoticeList] = useState([]); // 일반 목록
  const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 페이지 이동
  const movePage = num => {
    setSearchParams({ ...searchParams, page: num });
  };

  // 상세 페이지 이동
  const moveToDetail = idx => {
    navigate(`${RouterPath.NoticeDetail}/${idx}`, { state: { page } });
  };

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 공지사항 리스트
  const getNoticeList = async () => {
    try {
      const { data } = await apiGetNoticeList({
        page,
        size: 5,
      });

      if (data.code === 200) {
        setPinnedList([...data.data.topList]);
        setNoticeList([...data.data.bodyList.list]);
        setTotalPages(data.data.bodyList.totalPages);

        // categoryCode: 'name';
        // categoryName: '양의진';
        // keyword: null;
        // language: 'ko';
        // noticeBody: '안녕하세요1';
        // noticeFiles: null;
        // noticeIdx: 37;
        // noticeTitle: '등록 테스트1';
        // page: 0;
        // regDate: '2024-01-15 17:35:28.0';
        // size: 0;
        // topYn: 'Y';
        // type: null;
        // updDate: '2024-01-17 09:19:44.0';
        // useYn: 'Y';
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getNoticeList();

    return () => {
      setNoticeList([]);
    };
  }, [searchParams]);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <main id="notices">
      {/* 헤더 */}
      <Header type="basic" />
      <section className="main-section">
        <div className="main-img">
          <img src={images.noticesImg} alt="메인 이미지" />
        </div>
      </section>
      {/* 공지사항 - 상단 고정  */}
      <section className="contents-section1 inner">
        <div className="contents-container">
          <div className="contents-list">
            <h2>공지사항</h2>
            <p className="text">골든힐 한의원의 소식을 알려드립니다.</p>
            {!loadingShow && (
              <div className="contents-fix">
                {pinnedList.length > 0 &&
                  pinnedList.map((notice, index) => {
                    return (
                      <div
                        className="contents-item"
                        key={`notice-list-${index}`}
                        onClick={() => moveToDetail(notice.noticeIdx)}
                      >
                        <div className="title">{notice.noticeTitle}</div>
                        <div className="title-box">
                          <div className="day">{notice.regDate}</div>
                          <div className="icon">
                            <img src={images.icFix} alt="fix-icon" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* 공지사항 - 일반 */}
      {!loadingShow && (
        <section className="contents-section2 inner">
          <div className="contents-container">
            <div className="contents-list">
              {noticeList.length > 0 ? (
                noticeList.map((notice, index) => {
                  return (
                    <div
                      key={`notice-list-${index}`}
                      className="contents-item"
                      onClick={() => moveToDetail(notice.noticeIdx)}
                    >
                      <div className="title">{notice.noticeTitle}</div>
                      <div className="day">{notice.regDate}</div>
                    </div>
                  );
                })
              ) : (
                <div className="contents-none">
                  <div className="icon">
                    <img src={images.icWarning} alt="느낌표" />
                  </div>
                  <p>등록된 게시글이 없습니다.</p>
                </div>
              )}
            </div>

            {/* 페이징 */}
            {totalPages > 0 && (
              <CustomPagination
                pageCount={totalPages}
                pageNum={page}
                setPage={movePage}
              />
            )}
          </div>
        </section>
      )}
      {/* 최상단 이동 */}
      <TopButton />
      {/* 푸터 */}
      <Footer />
    </main>
  );
});
