/* eslint-disable no-case-declarations */
import {
  AccessDeniedException,
  CustomException,
  InvalidUserException,
  NetworkException,
} from '../common/exceptions';

// Util
import Utils from './Utils';

// Constant
import RouterPath from '../common/constants/RouterPath';
import RESULT_CODE from '../common/constants/ResultCode';

// eslint-disable-next-line default-param-last
export const handleError = (error, callback) => {
  switch (true) {
    case error instanceof CustomException:
    case error instanceof NetworkException:
      Utils.openModal({
        body: error.message,
        closeEvent: () => {
          if (callback) callback();
        },
      });
      break;
    // InvalidUserException
    case error instanceof InvalidUserException:
      Utils.openModal({
        body: error.message,
        closeEvent: () => {
          if (callback) callback();
          else window.location.href = RouterPath.Home;
        },
      });
      break;
    // AccessDeniedException
    case error instanceof AccessDeniedException:
      Utils.openModal({
        body: error.message,
        closeEvent: () => {
          if (callback) callback();
          else window.history.go(-1);
        },
      });
      // go back
      break;
    // default
    default:
      const message = error?.message || RESULT_CODE.CODE_500;
      Utils.openModal({
        body: message,
        closeEvent: () => {
          if (callback) callback();
        },
      });
  }
};
