/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { images } from '@assets';

// 참고 https://react-select.com/home
function CustomSelect({ name, value, options, onChange, disabled, ...rest }) {
  // console.log(value);
  return (
    <Select
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      isDisabled={disabled}
      noOptionsMessage={() => '선택 옵션이 없습니다.'}
      maxMenuHeight={400}
      isSearchable={false}
      {...rest}
    />
  );
}
const customStyles = {
  // select container
  container: (provided, state) => ({
    ...provided,
  }),

  // select box (input box)
  control: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: 'var(--fs-18)',
    fontWeight: '500',
    color: '#434343',
    backgroundColor: '#E8E8E8',
    borderRadius: state.isFocused ? '0.5rem 0.5rem 0 0' : '0.5rem',
    border: 'none',
    outline: 'none',
    boxShadow: state.isFocused ? 'none' : 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '1.25rem',
  }),
  // Select 아래 option 스타일 적용시
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '0.5rem 1.25rem',
    fontSize: 'var(--fs-18)',
    color: state.isSelected ? 'var(--bs-primary) !important' : '#434343',
    fontWeight: state.isSelected ? 700 : 500,
    backgroundColor: '#E7E7E7',
    ':hover': {
      backgroundColor: 'var(--bs-gray-100)',
    },
    ':active': {
      backgroundColor: 'var(--bs-gray-300)',
    },
  }),
  // option 스타일
  menu: provided => ({
    ...provided,
    padding: '0',
    margin: '0',
    border: 'none',
    boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '0 0 0.5rem 0.5rem',
  }),
  // option 개별 스타일
  menuList: provided => ({
    ...provided,
    backgroundColor: '#E7E7E7',
    borderRadius: '0 0 0.5rem 0.5rem',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
};

export default CustomSelect;
