import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RouterPath } from '../common';
import Layout from './Layout';

// 메타 데이터
import MetaTag from '../common/constants/MetaTag';

// 페이지 컴포넌트 임포트
import Error from '../pages/Errors';
import Landing from '../pages/Landing';
import Home from '../pages/Home';
import Introduction from '../pages/About/Introduction';
import Doctors from '../pages/About/Doctors';
import Directions from '../pages/About/Directions';
import Growth from '../pages/Health/Growth';
import Prostate from '../pages/Health/Prostate';
import Focus from '../pages/Health/Focus';
import Diet from '../pages/Health/Diet';
import Review from '../pages/Review/Review';
import ReviewDetail from '../pages/Review/ReviewDetail';
import Notices from '../pages/Notices/Notices';
import Faqs from '../pages/Notices/Faqs';
import Reservations from '../pages/Notices/Reservations';
import ReservationsWrite from '../pages/Notices/ReservationsWrite';
import ReservationsClear from '../pages/Notices/ReservationsClear';
import LayoutAuth from './LayoutAuth';
import NoticeDetail from '../pages/Notices/NoticeDetail';
import LoginCallbackGoogle from '../pages/Auth/LoginCallbackGoogle';
import LoginCallbackKakao from '../pages/Auth/LoginCallbackKakao';
import TermPrivate from '../pages/Term/TermPrivate';

const routeConfig = [
  {
    path: RouterPath.slash,
    element: (
      <>
        <MetaTag
          description="100년 전통이 만드는 오늘 더 건강한 삶"
          path={RouterPath.slash}
        />
        <Landing />
      </>
    ),
  },
  {
    path: RouterPath.Home,
    element: (
      <>
        <MetaTag
          description="100년 전통이 만드는 오늘 더 건강한 삶"
          path={RouterPath.Home}
        />
        <Home />
      </>
    ),
  },
  {
    path: RouterPath.Introduction,
    element: (
      <>
        <MetaTag
          description="100년 전통 한의 명가"
          path={RouterPath.Introduction}
        />
        <Introduction />
      </>
    ),
  },
  {
    path: RouterPath.Doctors,
    element: (
      <>
        <MetaTag description="의료진 소개" path={RouterPath.Doctors} />
        <Doctors />
      </>
    ),
  },
  {
    path: RouterPath.Directions,
    element: (
      <>
        <MetaTag
          description="진료 시간, 오시는 길"
          path={RouterPath.Directions}
        />
        <Directions />
      </>
    ),
  },
  {
    path: RouterPath.Growth,
    element: (
      <>
        <MetaTag
          description="키성장클리닉 100년 전통 한의원, 오늘보다 더 건강한 삶을 만나보세요."
          path={RouterPath.Growth}
        />
        <Growth />
      </>
    ),
  },
  {
    path: RouterPath.Prostate,
    element: (
      <>
        <MetaTag
          description="골든에이지 전립선 솔루션 연구센터"
          path={RouterPath.Prostate}
        />
        <Prostate />
      </>
    ),
  },
  {
    path: RouterPath.Focus,
    element: (
      <>
        <MetaTag
          description="골든스터디 집중력 연구센터"
          path={RouterPath.Focus}
        />

        <Focus />
      </>
    ),
  },
  {
    path: RouterPath.Diet,
    element: (
      <>
        <MetaTag
          description="골든밸런스 다이어트 체질분석 연구센터"
          path={RouterPath.Diet}
        />
        <Diet />
      </>
    ),
  },
  {
    path: `${RouterPath.ReviewDetail}/:idx`,
    element: (
      <>
        <MetaTag description="치료 후기" path={RouterPath.Review} />
        <ReviewDetail />
      </>
    ),
    auth: true,
  },
  {
    path: RouterPath.Review,
    element: (
      <>
        <MetaTag description="치료 후기" path={RouterPath.Review} />
        <Review />
      </>
    ),
    auth: true,
  },
  {
    path: `${RouterPath.NoticeDetail}/:idx`,
    element: (
      <>
        <MetaTag description="공지사항" path={RouterPath.Notices} />
        <NoticeDetail />
      </>
    ),
  },
  {
    path: RouterPath.Notices,
    element: (
      <>
        <MetaTag description="공지사항" path={RouterPath.Notices} />
        <Notices />
      </>
    ),
  },
  {
    path: RouterPath.Faqs,
    element: (
      <>
        <MetaTag description="자주 묻는 질문" path={RouterPath.Faqs} />
        <Faqs />
      </>
    ),
  },
  {
    path: RouterPath.Reservations,
    element: (
      <>
        <MetaTag description="상담 예약 신청" path={RouterPath.Reservations} />
        <Reservations />
      </>
    ),
  },
  {
    path: RouterPath.ReservationsWrtie,
    element: (
      <>
        <MetaTag
          description="상담 예약 신청"
          path={RouterPath.ReservationsWrtie}
        />
        <ReservationsWrite />
      </>
    ),
  },
  {
    path: RouterPath.ReservationsClear,
    element: (
      <>
        <MetaTag
          description="상담 예약 신청"
          path={RouterPath.ReservationsClear}
        />
        <ReservationsClear />
      </>
    ),
  },
  {
    path: RouterPath.LoginCallbackGoogle,
    element: <LoginCallbackGoogle />,
  },
  {
    path: RouterPath.LoginCallbackKakao,
    element: <LoginCallbackKakao />,
  },
  {
    path: RouterPath.TermPrivate,
    element: (
      <>
        <MetaTag description="개인정보처리방침" path={RouterPath.TermPrivate} />
        <TermPrivate />
      </>
    ),
  },
  {
    path: '*',
    element: <Error />,
  },
];

function Router() {
  return (
    <Routes>
      {routeConfig.map(({ path, element, auth = false }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              auth ? (
                <LayoutAuth>{element}</LayoutAuth>
              ) : (
                <Layout>{element}</Layout>
              )
            }
          />
        );
      })}
    </Routes>
  );
}

export default Router;
