import React from 'react';
import ReactPaginate from 'react-paginate';
import { images } from '../assets';

// TODO ::: CSS
function CustomPagination({ pageCount, pageNum, setPage, ...rest }) {
  return (
    <div className="page-container d-flex">
      {/* 첫 페이지 이동 */}
      <div className="page-btn" onClick={() => pageNum !== 1 && setPage(1)}>
        <img src={images.icPagination1} alt="첫 페이지" />
      </div>
      {/* 페이지 목록 */}
      <ReactPaginate
        pageCount={pageCount || pageNum || 1}
        onPageChange={e => {
          setPage(e.selected + 1);
        }}
        forcePage={pageNum ? pageNum - 1 : 0}
        previousLabel={<img src={images.icPagination2} alt="이전 페이지" />}
        nextLabel={<img src={images.icPagination3} alt="다음 페이지" />}
        breakLabel="..."
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link prev"
        nextClassName="page-item"
        nextLinkClassName="page-link next"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        {...rest}
      />
      {/* 끝 페이지 이동 */}
      <div
        className="page-btn"
        onClick={() => pageNum !== pageCount && setPage(pageCount)}
      >
        <img src={images.icPagination4} alt="끝 페이지" />
      </div>
    </div>
  );
}

export default CustomPagination;
