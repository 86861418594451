import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { infoAction } from '@reducers/infoSlice';
import { useSearchParams } from 'react-router-dom';
import { store } from './store';

// API
import { apiGetHomeAbout } from './api/RestAPI';

// Util
import { handleError } from './utils/HandleError';

// Constants
import Router from './router/Router';

// Components
import Loading from './components/Loading';

// ***********************************************************
// [ APP ]
// ***********************************************************
function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const nvid = searchParams.get('nvid');
  // console.log('web site params :::: ', nvid);
  if (nvid !== null) {
    localStorage.setItem('goldenHillNvid', nvid);
    localStorage.setItem(
      'goldenHillNvidExpire',
      `${new Date().toISOString().slice(0, 10)}`,
    );
  }
  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // 로딩 스피너 등록 ( with 'Redux' )
  const loadingShow = useSelector(state => {
    return state.loading?.loadingShow;
  });

  // ***********************************************************
  // [ API ]
  // ***********************************************************

  // 페이지 기본 정보 > Redis 조회 > Redux 저장
  const getHomeAbout = async () => {
    try {
      const { data } = await apiGetHomeAbout();

      if (data) {
        const {
          addressNew, // 도로명 주소
          addressOld, // 지번 주소
          businessName, // 상호명
          businessNumber, // 사업자등록번호
          ceoName, // 대표 이름
          hp, // 대표 전화
          instagramUrl, // 인스타그램 URL
          kakaoUrl, // 카카오 URL
          naverUrl, // 네이버 URL
          youtubeUrl, // 유튜브 URL
          pax, // 팩스
        } = data.data;

        store.dispatch(
          infoAction.setInfo({
            addressNew,
            addressOld,
            businessName,
            businessNumber,
            ceoName,
            hp,
            instagramUrl,
            kakaoUrl,
            naverUrl,
            youtubeUrl,
            pax,
          }),
        );
      }
    } catch (e) {
      handleError(e);
    }
  };

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************
  useEffect(() => {
    getHomeAbout();
  }, []);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <div className="App">
      {loadingShow && <Loading />}
      <Router />
    </div>
  );
}

export default React.memo(App);
