import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';

// Constant
import { RouterPath } from '../common';
import { MODAL_TYPES } from '../components/Modal/GlobalModal';

// Util
import Utils from '../utils/Utils';

// ***********************************************************
// [ 레이아웃 > 로그인 회원 ]
// ***********************************************************
function LayoutAuth({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // 로그인 유무
  const auth =
    localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth'));
  const isAuthorized = auth && auth?.accessToken && auth?.refreshToken;

  // ***********************************************************
  // [ Util ]
  // ***********************************************************

  // children에 auth 주입
  const childrenWithAuth = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { isAuthorized });
    }
    return child;
  });

  // ***********************************************************
  // [ useEffect ]
  // ***********************************************************

  useEffect(() => {
    // 로그인 필수 페이지
    if (pathname.startsWith(RouterPath.Review) && !isAuthorized) {
      Utils.openModal({
        type: MODAL_TYPES.LoginModal,
        data: { pathname },
        closeEvent: () => navigate(-1),
      });
    }
    // 일반 페이지
    else if (!isAuthorized) {
      Utils.openModal({
        closeEvent: () => navigate(-1),
      });
    }
  }, [pathname]);

  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    <Container fluid className="app-container">
      <div className="app-contents">{childrenWithAuth}</div>
    </Container>
  );
}

export default React.memo(LayoutAuth);
