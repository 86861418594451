const REVIEW_TYPE = {
  DIET: {
    label: '다이어트',
    value: 'DIET',
  },
  HEIGHT: {
    label: '키성장',
    value: 'HEIGHT',
  },
  PROSTATE: {
    label: '전립선',
    value: 'PROSTATE',
  },
  FOCUS: {
    label: '집중력',
    value: 'FOCUS',
  },
};

export default REVIEW_TYPE;
