import React, { useEffect, useState } from 'react';
import { images } from '../../assets';

export default React.memo(function TopButton(props) {
  // 실행 환경 > Naver 확인
  const agent = window.navigator.userAgent.toLowerCase();
  const isOnNaver = agent.indexOf('naver') > -1;

  const [showTopBtn, setShowTopBtn] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;
    const footer = document.querySelector('footer');
    const footerTop = footer ? footer.offsetTop : 0;
    const windowHeight = window.innerHeight;
    const topButton = document.querySelector('.top-btn'); // 버튼 요소 선택

    if (scrollY > 100) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }

    // topButton 요소가 존재하는 경우에만 스타일 적용
    if (topButton) {
      if (footer && scrollY + windowHeight > footerTop) {
        const bottomPosition = scrollY + windowHeight - footerTop + 40;
        topButton.style.bottom = `${bottomPosition}px`;
      } else {
        topButton.style.bottom = '40px';
      }
    }
  };

  // 모바일 환경을 감지하는 함수
  const isMobile = () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  // ***********************************************************
  // [ Return ]
  // ***********************************************************
  return (
    showTopBtn &&
    !isOnNaver && (
      <div
        className={`top-btn ${isHovered ? 'hovered' : ''}`}
        style={{ position: 'fixed', bottom: '40px', right: '40px' }}
        onMouseEnter={() => !isMobile() && setIsHovered(true)}
        onMouseLeave={() => !isMobile() && setIsHovered(false)}
        onClick={moveToTop}
      >
        <img
          src={isHovered ? images.icTopButtonWhite : images.icTopButton}
          alt="top-button"
        />
      </div>
    )
  );
});
